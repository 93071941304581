import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { useState } from "react";
import { ButtonCus, Text } from "components";
import { fontSize, mainColor } from "theme/default";
import { logoAuth } from "assets/images";
import CustomFormControlInput from "components/Inputs/InputOutLine";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from "utils";
import { FormRegisterData } from "types";
import { Icons } from "assets";
import { useMutation } from "@tanstack/react-query";
import { authService } from "services/authService";
import { useAlert, useTimer } from "hooks";
import { useNavigate } from "react-router-dom";
import CustomCheckbox from "components/Checkbox";

const RegisterPage = () => {
	const { success, warn } = useAlert();
	const navigate = useNavigate();
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid, isSubmitting },
	} = useForm<FormRegisterData>({
		resolver: yupResolver(registerSchema),
	});
	const [showPassword, setShowPassword] = useState(false);
	const [showCpw, setShowCpw] = useState(false);
	const { startTimer } = useTimer(300);

	const registerMutation = useMutation({
		mutationFn: (formData: FormRegisterData) => authService.register(formData),
		onSuccess: ({ data }) => {
			if (data?.statusCode === 200) {
				success("Enter OTP is sent to your email");
				navigate("/verify-email", { state: data.data.email });
				startTimer();
				reset();
				return;
			}
			warn(data?.message || "Register failed");
		},
		onError: () => {
			warn("Register failed");
		},
	});

	const onSubmit: SubmitHandler<FormRegisterData> = (data) => {
		console.log(data);
		registerMutation.mutate(data);
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleClickShowPcw = () => setShowCpw(!showCpw);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: mainColor.primary,
			}}>
			<Box
				bgcolor={mainColor.mainBlack}
				width={"559px"}
				height={"860px"}
				borderRadius={"20px"}
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					padding: 3,
				}}>
				<Box mb={5}>
					<img src={logoAuth} alt="logo" />
				</Box>
				<Text
					variant="h2"
					gutterBottom
					useI18n
					sx={{ fontWeight: 500 }}
					color={mainColor.textMain}>
					Sign up
				</Text>
				<Text
					variant="h6"
					gutterBottom
					useI18n
					sx={{ fontWeight: 400 }}
					color={mainColor.textMain}>
					Create an account to explore our platform
				</Text>
				<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
					<Controller
						name="name"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<CustomFormControlInput
								label="Name"
								value={field.value}
								setValue={field.onChange}
								error={!!errors.name}
								errorMessage={errors?.name?.message}
								fullWidth
								sx={{
									mt: 5,
									mb: 2.5,
								}}
							/>
						)}
					/>
					<Controller
						name="email"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<CustomFormControlInput
								label="Email"
								value={field.value}
								setValue={field.onChange}
								error={!!errors.email}
								errorMessage={errors?.email?.message}
								fullWidth
								sx={{
									mb: 2.5,
								}}
							/>
						)}
					/>
					<Controller
						name="password"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<CustomFormControlInput
								label="Password"
								type={showPassword ? "text" : "password"}
								value={field.value}
								setValue={field.onChange}
								error={!!errors.password}
								errorMessage={errors.password?.message}
								fullWidth
								sx={{
									mb: 2.5,
								}}
								icon={<>{showPassword ? <Icons.Eye /> : <Icons.EyeHide />}</>}
								onClickIcon={handleClickShowPassword}
							/>
						)}
					/>
					<Controller
						name="confirmPassword"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<CustomFormControlInput
								label="Confirm Password"
								type={showCpw ? "text" : "password"}
								value={field.value}
								setValue={field.onChange}
								error={!!errors.confirmPassword}
								errorMessage={errors.confirmPassword?.message}
								fullWidth
								sx={{
									mb: 2.5,
								}}
								icon={<>{showCpw ? <Icons.Eye /> : <Icons.EyeHide />}</>}
								onClickIcon={handleClickShowPcw}
							/>
						)}
					/>
					<Controller
						name="acceptPrivacy"
						control={control}
						render={({ field }) => (
							<CustomCheckbox
								checked={field.value}
								title="I’ve read and accept all the Terms and Conditions"
								onChange={field.onChange}
								error={!!errors.acceptPrivacy}
								errorMessage={errors?.acceptPrivacy?.message}
								fullWidth
								labelSx={{
									fontSize: fontSize.xs,
									color: mainColor.textMain,
								}}
							/>
						)}
					/>
					<Controller
						name="subscribeEmail"
						control={control}
						render={({ field }) => (
							<CustomCheckbox
								checked={!!field.value}
								title="I agree to the Privacy Policy"
								onChange={field.onChange}
								error={!!errors.acceptPrivacy}
								errorMessage={errors?.acceptPrivacy?.message}
								fullWidth
								labelSx={{
									fontSize: fontSize.xs,
									color: mainColor.textMain,
								}}
							/>
						)}
					/>
					<ButtonCus
						sx={{
							mt: 2,
							backgroundColor: isValid
								? mainColor.secondary
								: mainColor.secondary,
						}}
						fullWidth
						type="submit"
						isLoading={isSubmitting}
						disabled={!isValid || isSubmitting}>
						<Text
							useI18n
							color={mainColor.textMain}
							fontSize={fontSize.sm}
							fontWeight={"600"}>
							Sign Up
						</Text>
					</ButtonCus>
				</form>

				<Typography variant="body1" sx={{ mt: 2 }}>
					<Text useI18n color={mainColor.textMain}>
						Already have an account?
					</Text>
					<Link variant="body1" href="/login" underline="hover">
						{" "}
						<Text useI18n variant="body1" color={mainColor.secondary}>
							Login
						</Text>
					</Link>
				</Typography>
			</Box>
		</Box>
	);
};

export default RegisterPage;
