import React from "react";
import {
	Avatar,
	Box,
	Card,
	CardContent,
	Divider,
	Grid,
	Typography,
} from "@mui/material";
import useUserStore from "stores/userStore";

const ProfilePage = () => {
	const { user } = useUserStore();

	return (
		<Card sx={{ maxWidth: 400, margin: "0 auto", mt: 5 }}>
			<CardContent>
				<Grid container spacing={2} alignItems="center">
					<Grid item>
						<Avatar
							alt={user?.userName}
							src={user?.avatar}
							sx={{ width: 80, height: 80 }}
						/>
					</Grid>
					<Grid item>
						<Typography variant="h5">{user?.userName}</Typography>
						<Typography color="textSecondary">{user?.email}</Typography>
					</Grid>
				</Grid>

				<Divider sx={{ my: 2 }} />

				<Box>
					<Typography variant="body1">
						<strong>ID:</strong> {user?.id}
					</Typography>
					<Typography variant="body1">
						<strong>Plan:</strong> {user?.plan}
					</Typography>
					<Typography variant="body1">
						<strong>Credits:</strong> {user?.credits}
					</Typography>
					<Typography variant="body1">
						<strong>Total Bill:</strong> ${user?.totalBill}
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
};

export default ProfilePage;
