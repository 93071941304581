import React, { useCallback } from "react";
import { Box, ImageList } from "@mui/material";
import { ImageListProps, IModelType } from "types";
import LoadingListImg from "components/LoadingListImg";
import ImageDrag from "components/ImageDrag/ImageDrag";
import { RuleFolder } from "@mui/icons-material";
import { Text } from "components";
import { mainColor } from "theme/default";

const ListImage: React.FC<ImageListProps> = ({
	images,
	selectedImage,
	setSelectedImage,
	isLoading,
	dropType,
}) => {
	const handleClick = useCallback((image: IModelType) => {
		setSelectedImage?.(image);
	}, []);

	const renderDataLoading = useCallback(() => {
		if (isLoading) {
			return <LoadingListImg />;
		} else {
			return (
				<Box
					width={"100%"}
					height={"190px"}
					component={"div"}
					textAlign={"center"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					flexDirection={"column"}
					color={"white"}>
					<RuleFolder style={{ width: "40px", height: "40px" }} />
					<Text color={mainColor.textGray} fontStyle={"italic"}>
						This item currently has no data.
					</Text>
				</Box>
			);
		}
	}, [isLoading]);

	return (
		<>
			{!images?.length || isLoading ? (
				renderDataLoading()
			) : (
				<ImageList
					sx={{ width: "100%", height: "auto", overflow: "hidden" }}
					cols={5}
					rowHeight={"auto"}>
					{images.map((item) => (
						<ImageDrag
							selectedImage={selectedImage}
							handleClick={handleClick}
							item={item}
							key={item?.id}
							dropType={dropType}
						/>
					))}
				</ImageList>
			)}
		</>
	);
};

export default ListImage;
