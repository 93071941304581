import { useMutation, useQuery } from "@tanstack/react-query";
import { userService } from "services/userService";
import { KEY_CONTEXT, QueryKeys } from "utils";
import { useAlert } from "./useAlert";
import useUserStore from "stores/userStore";
import { UpdateProfileForm } from "types";
import { useEffect } from "react";
import { authService } from "services/authService";
import { useKey } from "./useKey";

export const useUser = () => {
	const { warn } = useAlert();
	const { setUser, user, setOrigin, origin } = useUserStore();
	const { setKey } = useKey()

	const { data, refetch } = useQuery({
		queryKey: [QueryKeys.PROFILE],
		queryFn: () => userService.getProfile(),
		enabled: !user
	});

	const { mutate } = useMutation({
		mutationFn: (form: UpdateProfileForm) => userService.updateProfile(form),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				const rs = await userService.getProfile();
				if (rs?.data?.data) {
					setUser(rs.data?.data);
				}
				return;
			}
			warn(data?.message || "Login failed");
		},
		onError: (error) => {
			console.log("🚀 ~ LoginPage ~ error:", error);
			warn("Login failed!");
		},
	});

	useEffect(() => {
		if (data) {
			setUser(data?.data?.data);
		}
	}, [data]);

	const loginWithMerchantLink = async (link: string, cb?: (t: boolean) => void) => {
		try {
			const {data} = await authService.link(link);
			const d = data?.data;
			if(d) {
				const { accessToken, refreshToken } = d.token;
				setKey(KEY_CONTEXT.AUTH_TOKEN, accessToken);
				setKey(KEY_CONTEXT.REFRESH_TOKEN, refreshToken);
				setUser(d);
				
				cb?.(true);
			}
		
		} catch (error) {
			console.log("🚀 ~ loginWithMerchantLink ~ error:", error)
			cb?.(false);
		}
	}

	return {
		user,
		origin,
		setOrigin,
		loginWithMerchantLink,
		getProfile: refetch,
		updateProfile: mutate,
	};
};
