/** @format */
import React, { forwardRef, memo, useMemo } from "react";
import { Button as ButtonMui, CircularProgress } from "@mui/material";
import { Icons } from "assets";
import { Text } from "components/Text";
import { useKey } from "hooks";
import theme from "theme/theme";
import { IButton, ISXTheme, IThemeColor } from "types";
import { KEY_CONTEXT } from "utils";
import createStyle from "./styles";
import { fontSize } from "theme/default";

const Button = forwardRef<HTMLButtonElement, IButton>(function Button(
	{
		title,
		useI18n,
		variantTitle = "body1",
		LabelProps,
		variant = "outlined",
		variantStyle = "default",
		iconProps: icon,
		children,
		sx,
		disabled = false,
		startIcon,
		isLoading = false,
		...props
	},
	ref,
) {
	// styles
	const { getKey } = useKey();
	const mode = getKey(KEY_CONTEXT.THEME_MODE) as IThemeColor;
	const styles = createStyle();
	const btnStyle = useMemo(
		() => styles[variant][variantStyle] as ISXTheme,
		[variant, variantStyle],
	);
	const stylesOverride = useMemo(() => {
		const baseStyles = [styles.base, btnStyle];
		if (Array.isArray(sx)) {
			return [...baseStyles, ...sx];
		}
		return [...baseStyles, sx];
	}, [sx, btnStyle]) as ISXTheme;

	const iconColor = useMemo(
		() =>
			icon?.color || variant !== "contained"
				? disabled
					? "rgba(0, 0, 0, 0.26)"
					: theme.palette.primary[mode]
				: "white",
		[icon, variant],
	);

	// ICON default
	const IconButton = useMemo(
		() => ({
			close: (
				<Icons.Close color={iconColor} size={icon?.size || 12} {...icon} />
			),
			cancel: (
				<Icons.Close color={iconColor} size={icon?.size || 12} {...icon} />
			),
		}),
		[icon],
	);

	return (
		<ButtonMui
			ref={ref}
			sx={stylesOverride}
			startIcon={startIcon || (icon?.variant && IconButton?.[icon?.variant])}
			disabled={disabled}
			{...props}>
			{title ? (
				<Text
					useI18n={useI18n}
					fontSize={fontSize.sm}
					variant={variantTitle}
					{...LabelProps}>
					{title}
				</Text>
			) : (
				children
			)}
			{isLoading && <CircularProgress size={"20px"} sx={{ ml: "10px" }} />}
		</ButtonMui>
	);
});

export default memo(Button);
