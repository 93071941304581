import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { paymentService } from "services/paymentService";
import { formatMoney, QueryKeys } from "utils";
import { Box, Button, Divider, Grid } from "@mui/material";
import { Text } from "components";
import { fontSize, mainColor } from "theme/default";
import ButtonToggle from "components/ButtonToggle";
import { ResponsePlan } from "types/payment";
import { useNavigate } from "react-router-dom";
import { DATA_BUTTON } from "utils/data";
import { TrendingFlat } from "@mui/icons-material";

const PricingPlan = () => {
	const navigate = useNavigate();
	const [typePlan, setTypePlan] = useState(DATA_BUTTON[0]);
	const { data } = useQuery({
		queryKey: [QueryKeys.GET_PLAN, typePlan.value],
		queryFn: () => paymentService.listPlan(typePlan.value),
	});

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"space-between"}
			gap={"30px"}
			paddingY={"30px"}>
			<Box
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				flexDirection={"column"}
				gap={"20px"}
				marginBottom={"10px"}>
				<Text
					useI18n
					color={mainColor.textMain}
					fontSize={fontSize.xl}
					fontWeight={"700"}>
					Choose Your Stylize Plan
				</Text>
				<Divider sx={{ width: "260px", bgcolor: "white" }} />
				<Text color={mainColor.textMain} fontSize={fontSize.xs}>
					Right option with greater deal.
				</Text>
			</Box>
			<ButtonToggle typePlan={typePlan} setTypePlan={setTypePlan} />

			<Grid container spacing={2} marginTop={"30px"}>
				{data?.data?.map((v: ResponsePlan) => {
					const isFree = v.packageName === "FREE";
					return (
						<Grid key={v?._id} item xs={12} sm={6} lg={3} minHeight={"650px"}>
							<Box
								height={"100%"}
								bgcolor={mainColor.mainBlack}
								display={"flex"}
								flexDirection={"column"}
								padding={"20px"}
								justifyContent={"space-between"}
								borderRadius={"10px"}>
								<Box display={"flex"} flexDirection={"column"} gap={"20px"}>
									<Box display={"flex"} alignItems={"center"} gap={"20px"}>
										<Text
											color={mainColor.textMain}
											sx={{
												fontSize: {
													xs: fontSize.md,
													xl: fontSize.xl,
												},
											}}
											fontWeight={"bold"}>
											{v.packageName}
										</Text>
										{!isFree && (
											<Box
												borderRadius={"5px"}
												textAlign={"center"}
												color={mainColor.textMain}
												bgcolor={"#019A76"}
												width={"90px"}
												lineHeight={"25px"}
												height={"25px"}>
												{((v.priceSale / v.price) * 100).toFixed(2)}%
											</Box>
										)}
									</Box>
									<Box display={"flex"} gap={"10px"} alignItems={"center"}>
										{!isFree ? (
											<>
												<Box position={"relative"}>
													<Text
														color={mainColor.textGray}
														sx={{
															fontSize: {
																xs: fontSize.sm,
																xl: fontSize.md,
															},
														}}
														fontWeight={"bold"}>
														{formatMoney(v.price * typePlan.value)}
													</Text>
													<Box
														position={"absolute"}
														height={"3px"}
														width={"100%"}
														bgcolor={"#FFF"}
														top={"20%"}
														left={"50%"}
														sx={{
															transform: "rotate(350deg) translate(-50%,-50%)",
														}}
													/>
												</Box>
												<TrendingFlat />
											</>
										) : (
											<></>
										)}
										<Text
											color={mainColor.secondary}
											sx={{
												fontSize: {
													xs: "18px",
													xl: fontSize.xl,
												},
											}}
											fontWeight={"bold"}>
											{formatMoney(v.priceSale * typePlan.value)}
										</Text>
									</Box>
									<Text
										color={mainColor.textGray}
										sx={{
											fontSize: {
												xs: "12px",
												xl: fontSize.sm,
											},
										}}>
										{v.subTitle}
									</Text>
									<Box component={"ul"} paddingLeft={"30px"}>
										{v.description.map((item, index) => {
											return (
												<Box
													component={"li"}
													marginTop={"20px"}
													color={mainColor.textMain}
													fontSize={fontSize.sm}
													textTransform={"capitalize"}
													key={index}>
													{item.toLocaleLowerCase()}
												</Box>
											);
										})}
									</Box>
								</Box>
								<Button
									onClick={() =>
										navigate(
											isFree
												? `/outfit`
												: `/info-payment?id=${v._id}&quantity=${typePlan.value}`,
										)
									}
									sx={{
										height: "50px",
										lineHeight: "40px",
										margin: "20px",
										fontWeight: 600,
										fontSize: {
											xs: fontSize.sm,
											xl: fontSize.md,
										},
										backgroundColor: mainColor.secondary,
										border: `1px solid ${mainColor.secondary}`,
									}}>
									{isFree ? "GET STATED" : "BUY"}
								</Button>
							</Box>
						</Grid>
					);
				})}
			</Grid>
			<Text
				marginTop={"30px"}
				color={mainColor.textMain}
				fontSize={fontSize.xs}
				maxWidth={"1000px"}
				textAlign={"center"}>
				By providing your payment information and clicking “Buy”, you agree you
				will be charged the amount listed above and your subscription will
				automatically renew until you cancel (price subject to change).
			</Text>
		</Box>
	);
};

export default PricingPlan;
