import {create} from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

type State = {
	selectedFileModel: File | string | null;
	modelImage: string | null;
	selectedFileClothes: File | string | null;
	imageClothes: string | null;
	selectedGallery: File | string | null;
	selectedFileGallery: string | null;
}

type Actions = {
	setSelectedFileModel: (fileModel: File | string | null) => void;
	setModelImage: (image: string | null) => void;
	setSelectedFileClothes: (fileModel: File | string | null) => void;
	setImageClothes: (image: string | null) => void;
	setSelectedGallery: (img: File | string | null) => void;
	setSelectedFileGallery: (fileModel: string | null) => void
	reset: () => void
}

const initialState: State = {
	selectedFileModel: null,
	selectedFileClothes: null,
	modelImage: null,
	imageClothes: null,
	selectedGallery: null,
	selectedFileGallery: null,
}

const useOutfitStore = create<State & Actions>()(
	devtools(
		persist(
			(set) => ({
				...initialState,
				setSelectedFileModel: (file) => set({ selectedFileModel: file }),
				setSelectedFileClothes: (file) => set({ selectedFileClothes: file }),
				setModelImage: (img) => set({ modelImage: img }),
				setImageClothes: (img) => set({ imageClothes: img }),
				setSelectedGallery: (img) => set({ selectedGallery: img }),
				setSelectedFileGallery: (file) => set({ selectedFileGallery: file }),
				reset: () => {
					set(initialState)
				},
			}),
			{
				name: "Outfit-storage",
				storage: createJSONStorage(() => sessionStorage),
			},
		),
		{
			name: "OutfitStore",
		},
	),
);

export default useOutfitStore;
