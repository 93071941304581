import React from "react";
import { Box } from "@mui/material";
import { Icons } from "assets";
import { Text } from "components/Text";
import { fontSize, mainColor } from "theme/default";

const Heading = ({ title }: { title: string }) => {
	return (
		<Box
			display={"flex"}
			gap={"10px"}
			marginTop={"20px"}
			marginBottom={"10px"}
			alignItems={"center"}>
			{Icons.List()}
			<Text fontSize={fontSize.xs} color={mainColor.textMain}>
				{title}
			</Text>
		</Box>
	);
};

export default Heading;
