import { Box } from "@mui/material";
import React from "react";
import { EnumStatusOutfit, IOutfitFinalsProps } from "types";
import { ProcessResult } from "components/ProcessResult";

export const FinalResult: React.FC<IOutfitFinalsProps> = ({
	result: processing,
	isLoading,
}) => {
	return (
		<Box>
			<ProcessResult
				initialImage={
					processing?.message === "OK" ? (processing.url as string) : ""
				}
				title="Final Result"
				valueProcess={
					(processing?.currentStep && processing?.totalStep
						? processing?.currentStep / processing?.totalStep
						: 0) * 100
				}
				isLoading={isLoading}
				isStart={processing?.status === EnumStatusOutfit.STARTED}
			/>
		</Box>
	);
};
