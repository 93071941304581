import { Box } from "@mui/material";
import React from "react";
import { fontSize, mainColor } from "theme/default";
import { EPricingPlan } from "types";
import { DATA_BUTTON } from "utils/data";

interface IButtonToggle {
	typePlan: {
		value: number;
		name: EPricingPlan;
	};
	setTypePlan: React.Dispatch<
		React.SetStateAction<{
			value: number;
			name: EPricingPlan;
		}>
	>;
}

const ButtonToggle = ({ typePlan, setTypePlan }: IButtonToggle) => {
	return (
		<Box
			position={"relative"}
			width={"200px"}
			height={"40px"}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"space-between"}
			sx={{ transition: "all 0.25s ease-in-out", cursor: "pointer" }}
			borderRadius={"5px"}
			bgcolor={mainColor.secondary}>
			{DATA_BUTTON.map((item) => (
				<Box
					key={item.value}
					height={"100%"}
					width={"50%"}
					textAlign={"center"}
					lineHeight={"40px"}
					fontSize={fontSize.sm}
					fontWeight={"500"}
					color={mainColor.textD8}
					component={"div"}
					onClick={() => setTypePlan(item)}>
					{item.name}
				</Box>
			))}
			<Box
				width={"95px"}
				height={"32px"}
				textAlign={"center"}
				lineHeight={"37px"}
				position={"absolute"}
				borderRadius={"5px"}
				fontWeight={"bold"}
				bgcolor={"white"}
				top={"50%"}
				left={typePlan.name === EPricingPlan.MONTHLY ? "5px" : "50%"}
				sx={{
					transform: "translateY(-50%)",
					transition: "all 0.25s ease-in-out",
				}}>
				{typePlan.name}
			</Box>
		</Box>
	);
};

export default ButtonToggle;
