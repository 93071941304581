import React from "react";
import { Box, Grid } from "@mui/material";
import { ModelBody } from "./ModelBody";
import { Clothes } from "./Clothes";
import { FinalResult } from "./FinalResult";
import { useSocket } from "contexts";
import useUserStore from "stores/userStore";
import { useCallback, useEffect, useState } from "react";
import {
	EnumStatusOutfit,
	IFinalOutfitResult,
	IOutfitParams,
	TParams,
} from "types";
import { useAITools, useAlert, useModel } from "hooks";
import useOutfitStore from "stores/outfitStore";
import { mainColor } from "theme/default";
import { ButtonCus } from "components";

export const OutFit = () => {
	// ======================= HOOKS =========================
	const { socket } = useSocket();
	const { user } = useUserStore();
	const { error } = useAlert();
	const { generateOutfit } = useAITools();
	const { selectedFileModel, selectedFileClothes, selectedFileGallery } =
		useOutfitStore();

	// ======================= STATES =========================
	const [disabled, setDisabled] = useState<boolean>(false);
	const [processing, setProcessing] = useState<IFinalOutfitResult>();
	const [valueCategory, setValueCategory] = useState<string>("upper_body");
	const [valueSize, setValueSize] = useState<string>("full-length");

	// ======================== STATE GALLERY ========================
	const { useGetGalleryList } = useModel();
	const [paramsGallery, setParamsGallery] = useState<TParams>({
		page: 1,
		limit: 5,
	});
	const {
		data: dataGallery,
		refetch,
		isLoading: isLoadingGallery,
	} = useGetGalleryList(paramsGallery);

	// ====================== HANDLE GENERATE ===========================
	const handelGenerate = useCallback(async () => {
		setProcessing({
			...processing,
			currentStep: 0,
			totalStep: 0,
			status: EnumStatusOutfit.STARTED,
		});
		setDisabled(true);

		const formData = new FormData();

		formData.append("garmentCategory", valueCategory);
		formData.append("garmentLength", valueSize);

		if (typeof selectedFileClothes === "string") {
			formData.append("clothesId", selectedFileClothes);
		} else {
			formData.append("fileClothes", selectedFileClothes as File);
		}

		if (typeof selectedFileModel === "string") {
			formData.append("modelBodyId", selectedFileModel);
		} else if (typeof selectedFileGallery === "string") {
			formData.append("galleryId", selectedFileGallery);
		} else {
			formData.append("fileModel", selectedFileModel as File);
		}

		generateOutfit(formData as IOutfitParams, {
			onSuccess: async (res) => {
				if (res?.data?.statusCode === 400) {
					setDisabled(false);
					error(res.data?.message || "Please try again!");
					setProcessing({
						...processing,
						status: EnumStatusOutfit.FAILURE,
					});
				}
			},
			onError: () => {
				error("Please try again!");
				setDisabled(false);
				setProcessing({
					...processing,
					status: EnumStatusOutfit.FAILURE,
				});
			},
		});
	}, [
		selectedFileModel,
		selectedFileClothes,
		selectedFileGallery,
		valueCategory,
		valueSize,
	]);

	const handleActionStatus = useCallback(() => {
		setDisabled(false);
		refetch();
	}, []);

	useEffect(() => {
		if (socket) {
			socket.on(`eventOutfit-${user?.id}`, (data) => {
				setDisabled(true);
				console.log("==============> data", data);
				setProcessing(data);
				if (data?.status === EnumStatusOutfit.SUCCESS) {
					handleActionStatus();
				}
				if (data?.status === EnumStatusOutfit.FAILURE) {
					handleActionStatus();
					error("Please try again!");
				}
			});
			return () => {
				socket.off(`eventOutfit-${user?.id}`);
			};
		}
	}, [socket]);

	return (
		<>
			<Grid
				container
				spacing={3}
				paddingTop={"30px"}
				sx={{ paddingBottom: "100px" }}>
				<Grid item xs={12} sm={6} md={4}>
					<ModelBody
						dataGallery={dataGallery}
						setParamsGallery={setParamsGallery}
						paramsGallery={paramsGallery}
						isLoadingGallery={isLoadingGallery}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<Clothes
						valueCategory={valueCategory}
						setValueCategory={setValueCategory}
						valueSize={valueSize}
						setValueSize={setValueSize}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<FinalResult result={processing} isLoading={disabled} />
					{/* <Box mt={"40px"}>
						<Gallery dataGallery={dataGallery} />
					</Box> */}
				</Grid>
			</Grid>

			{/* ============= Footer =========== */}
			<Box
				sx={{
					borderTop: `1px solid ${mainColor.borderColor}`,
					position: "fixed",
					bottom: 0,
					left: "50%",
					transform: "translateX(-50%)",
					zIndex: 9999,
					width: "100%",
					backgroundColor: mainColor.blackDark,
					p: 2,
					justifyContent: "center",
					display: "flex",
				}}>
				<ButtonCus
					title="Generate"
					onClick={handelGenerate}
					disabled={!selectedFileClothes || disabled}
					LabelProps={{ sx: { color: mainColor.textMain, fontWeight: 600 } }}
					sx={{
						backgroundColor: mainColor.secondary,
						width: "600px",
						cursor: "pointer",
						borderRadius: "10px",
					}}
				/>
			</Box>
		</>
	);
};
