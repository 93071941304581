import React from "react";
import { Box, Grid } from "@mui/material";
import { mainColor } from "theme/default";
import useUserStore from "stores/userStore";
import CommonSelect from "components/Select";
import { LANGUAGES } from "utils/data";
import i18next from "i18next";
import Footer from "components/Footer";

interface SubLayoutProps {
	children: React.ReactNode;
}

export const SubLayout: React.FC<SubLayoutProps> = ({ children }) => {
	const { languages, setLanguages } = useUserStore();
	return (
		<Box
			component={"div"}
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"space-between"}
			alignItems={"center"}
			bgcolor={mainColor.primary}
			minHeight={"100vh"}
			height={"auto"}
			paddingBottom={"30px"}>
			<Grid container sx={{ height: { xs: "60px", md: "88px" } }} width={"95%"}>
				<Grid item xs={6}></Grid>
				<Grid
					item
					xs={6}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"end"}>
					<CommonSelect
						options={LANGUAGES}
						label={languages.name}
						name={languages.name}
						value={languages.value}
						onChange={(langs) => {
							setLanguages(langs.target);
							i18next.changeLanguage(langs.target.value);
						}}
					/>
				</Grid>
			</Grid>
			{children}
			<Footer />
		</Box>
	);
};
