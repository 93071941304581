export const END_POINT = {
	AUTH: {
		LOGIN: "/auth/login",
		REGISTER: "/auth/register",
		REFRESH_TOKEN: "/auth/refresh-token",
		CHANGE_PASSWORD: "/auth/change-password",
        LOGIN_SOCIAL: "/auth/login-social",
        VERIFY_OTP: '/auth/verify-otp',
        VERIFY_EMAIL: '/auth/verify-email',
        MERCHANT: '/merchants/login'
	},
	USER: {
		GET_PROFILE: "/user/me",
		LOGOUT: "/user/logout",
		UPDATE_PROFILE: "/user/profile"
	},
	OTP: {
		SEND_OTP: "/send-mail/otp"
	},
	MODEL: {
        GET_EXAMPLE: "/model-body/example"
    },
	CLOTHES: {
		GET_EXAMPLE: "/clothes/example"
	},
	AI_TOOLS: {
		OUTFIT_TOOLS: "/ai-tools/outfit",
		FACE_SWAP: "/ai-tools/face-swap"
	},
	GALLERY: {
		GET_GALLERY: "/gallery/user"
	},
	PAYMENT: {
		ORDER: '/payments/paypal/orders',
		APPROVE: '/payments/paypal/approve',
		PAYMENT_PAYPAL: '/payments',
		PLAN: '/plan',
		DETAIL_PLAN: '/plan/detail?id='
	}
};
