import React from "react";
import { Box, Button, Modal } from "@mui/material";
import { Text } from "components";
import useUserStore from "stores/userStore";
import { fontSize, mainColor } from "theme/default";
import { formatMoney } from "utils";
import { iconSuccess } from "assets/images";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TDetailBill } from "types/payment";
interface IModalSuccess {
	open: boolean;
	onClose: () => void;
	info: TDetailBill | null;
}
const ModalSuccess = ({ open, onClose, info }: IModalSuccess) => {
	const { user } = useUserStore();
	const navigate = useNavigate();
	return (
		<Modal
			keepMounted
			open={open}
			sx={{
				backgroundColor: "#00000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={() => { }}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Box
				display={"flex"}
				alignItems={"center"}
				justifyContent={"space-between"}
				padding={"20px 10px"}
				flexDirection={"column"}
				height={"500px"}
				sx={{
					width: {
						xs: "90%",
						sm: "400px",
					},
				}}
				borderRadius={"10px"}
				bgcolor={mainColor.textMain}>
				<Text fontWeight={"600"} fontSize={fontSize.md}>
					Payment Successful
				</Text>
				<img src={iconSuccess} alt="default" />
				<Box
					padding={"20px"}
					display={"flex"}
					width={"100%"}
					bgcolor={"#b3b6b657"}
					borderRadius={"5px"}
					alignItems={"center"}
					justifyContent={"space-between"}>
					<Text
						fontSize={fontSize.sm}
						color={mainColor.textD8}
						fontWeight={"600"}>
						Total credit
					</Text>
					<Text fontSize={fontSize.md} fontWeight={"600"}>
						{info?.totalCredit + ""}
					</Text>
				</Box>
				<Box
					bgcolor={"#b3b6b657"}
					borderRadius={"5px"}
					padding={"20px"}
					width={"100%"}
					display={"flex"}
					flexDirection={"column"}
					gap={"10px"}>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<Text
							fontSize={fontSize.sm}
							color={mainColor.textD8}
							fontWeight={"600"}>
							Invoice ID
						</Text>
						<Text fontSize={fontSize.sm}>{info?.invoiceId}</Text>
					</Box>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<Text
							fontSize={fontSize.sm}
							color={mainColor.textD8}
							fontWeight={"600"}>
							Date
						</Text>
						<Text fontSize={fontSize.sm}>
							{dayjs(info?.startDate).format("DD MMM YYYY HH:mm:ss")}
						</Text>
					</Box>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<Text
							fontSize={fontSize.sm}
							color={mainColor.textD8}
							fontWeight={"600"}>
							By user
						</Text>
						<Text fontSize={fontSize.sm}>{user?.userName}</Text>
					</Box>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<Text
							fontSize={fontSize.sm}
							color={mainColor.textD8}
							fontWeight={"600"}>
							Amount spent
						</Text>
						<Text fontSize={fontSize.sm}>
							{formatMoney(info?.amountSpent || 0)}
						</Text>
					</Box>
				</Box>
				<Button
					sx={{
						fontSize: fontSize.xs,
						fontWeight: "600",
					}}
					onClick={() => {
						onClose();
						navigate("/pricing-plan");
					}}>
					Back to Plan
				</Button>
			</Box>
		</Modal>
	);
};

export default ModalSuccess;
