import React, {
	createContext,
	useContext,
	useEffect,
	useState,
	ReactNode,
} from "react";
import { io, Socket } from "socket.io-client";

// Define the type for the context
interface SocketContextType {
	socket: Socket | null;
}

// Create a Context for the Socket
const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const useSocket = (): SocketContextType => {
	const context = useContext(SocketContext);
	if (!context) {
		throw new Error("useSocket must be used within a SocketProvider");
	}
	return context;
};

// Define the type for the provider props
interface SocketProviderProps {
	children: ReactNode;
}

// Create a Provider component
export const SocketProvider = ({
	children,
}: SocketProviderProps): JSX.Element => {
	const [socket, setSocket] = useState<Socket | null>(null);

	useEffect(() => {
		const newSocket = io(`${process.env.REACT_APP_SOCKET_URI}`, {
			transports: ["websocket"],
			autoConnect: true,
		});

		console.log(
			"STATUS CONNECT SOCKET ==========> ",
			newSocket,
			process.env.REACT_APP_SOCKET_URI,
		);

		newSocket.emit("handleEvent", "OK");
		setSocket(newSocket);

		// Cleanup function to disconnect the socket when the component unmounts
		return () => {
			if (newSocket) {
				newSocket.disconnect();
			}
		};
	}, []);

	return (
		<SocketContext.Provider value={{ socket }}>
			{children}
		</SocketContext.Provider>
	);
};
