import { useQuery } from "@tanstack/react-query";
import { modelService } from "services/modelService";
import { TParams } from "types";
import { END_POINT, QueryKeys } from "utils";

export const useModel = () => {
	
	const useGetModels = (params: TParams) => {
		const { data, refetch, isLoading, isError } = useQuery({
			queryKey: [QueryKeys.MODELS, params.page, params.typeModel],
			queryFn: () => modelService.getModelServiceList({ ...params }),
		});
		return {data, refetch, isLoading, isError}
	}

	const useGetGalleryList = (params: TParams) => {
		const {data, refetch, isLoading, isError  } = useQuery({
			queryKey: [QueryKeys.GALLERY_LIST, params.page],
			queryFn: () => modelService.getModelServiceList({ ...params }, END_POINT.GALLERY.GET_GALLERY),
		});
		return {data, refetch, isLoading, isError}
	}

	return {
		useGetModels,
		useGetGalleryList
	};
};
