import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import { IPaginationProps } from "types";

export const PaginationCommon: React.FC<IPaginationProps> = ({
	onChange,
	total,
}) => {
	const [currentPage, setCurrentPage] = useState(1);

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setCurrentPage(value);
		onChange?.(value);
	};

	return (
		<Pagination
			count={total}
			page={currentPage}
			onChange={handleChange}
			sx={{ marginTop: "10px" }}
		/>
	);
};
