import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import styled from "@emotion/styled";
import { FormControl, SxProps } from "@mui/material";
import { defaultColorTheme } from "theme/default";
import { Theme } from "@emotion/react";
import { Text } from "components/Text";
import { Icons } from "assets";

interface CustomCheckboxProps {
	checked: boolean;
	title: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	warning?: boolean;
	error?: boolean;
	fullWidth?: boolean;
	sx?: SxProps<Theme>;
	errorMessage?: string;
    labelSx?: SxProps<Theme>;
}

const CustomFormControl = styled(FormControl)<{
	warning?: boolean;
	error?: boolean;
}>(({ warning, error }) => ({
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: error
				? defaultColorTheme.warning
				: warning
					? defaultColorTheme.warning
					: defaultColorTheme.textMain, // Outline color based on error or warning
		},
		"&:hover fieldset": {
			borderColor: error
				? defaultColorTheme.warning
				: warning
					? defaultColorTheme.warning
					: defaultColorTheme.textMain, // Outline color on hover
		},
		"&.Mui-focused fieldset": {
			borderColor: error
				? defaultColorTheme.warning
				: warning
					? defaultColorTheme.warning
					: defaultColorTheme.textMain, // Outline color when focused
		},
	},
	"& .MuiInputLabel-root": {
		color: error
			? defaultColorTheme.warning
			: warning
				? defaultColorTheme.warning
				: defaultColorTheme.textMain,
		"&.Mui-focused": {
			color: error
				? defaultColorTheme.warning
				: warning
					? defaultColorTheme.warning
					: defaultColorTheme.textMain,
		},
	},
}));

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
	checked,
	title,
	onChange,
	warning,
	error,
	fullWidth,
	sx,
	errorMessage,
    labelSx
}) => {
	return (
		<CustomFormControl
			variant="outlined"
			warning={warning}
			error={error}
			fullWidth={fullWidth}
			sx={sx}>
			<FormControlLabel
				control={
					<Checkbox
						checked={checked}
						onChange={onChange}
						icon={<Icons.UnCheckBox />}
						checkedIcon={ checked ? <Icons.Checked /> : <Icons.UnCheckBox />}
					/>
				}
				label={title}
                sx={labelSx}
			/>
			{errorMessage && (
				<Text
					color={
						error
							? defaultColorTheme.warning
							: warning
								? defaultColorTheme.warning
								: defaultColorTheme.textMain
					}>
					{errorMessage}
				</Text>
			)}
		</CustomFormControl>
	);
};

export default CustomCheckbox;
