import React, { useEffect } from "react";
import useUserStore from "stores/userStore";
import { useUser } from "hooks";
import { OutFit } from "./components/OutFit/OutFit";

interface HomePageProps {
	element?: React.ReactElement;
}

const HomePage: React.FC<HomePageProps> = () => {
	const { user } = useUserStore(); // storage for user
	const { getProfile } = useUser(); // hooks refresh user
	useEffect(() => {
		if (!user) {
			getProfile();
		}
	}, [user]);

	return <OutFit />;
};

export default HomePage;
