import React from "react";
import { Box, Skeleton } from "@mui/material";

const LoadingListImg = ({ numberImage = 5 }: { numberImage?: number }) => {
	return (
		<Box
			width={"100%"}
			display={"flex"}
			gap={"5px"}
			alignItems={"center"}
			justifyItems={"space-between"}>
			{[...Array(numberImage)].map((_, index) => {
				return (
					<Skeleton
						key={index}
						variant="rectangular"
						sx={{
							flex: 1,
							backgroundColor: "#ccc",
							borderRadius: "5px",
							width: { xs: "67px", xl: "243px" },
							height: {
								xs: "89px",
								md: "100px",
								xl: "200px",
							},
						}}
					/>
				);
			})}
		</Box>
	);
};

export default LoadingListImg;
