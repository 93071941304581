import { useState, useEffect, useCallback } from "react";

export const useTimer = (initialSeconds: number) => {
	const [seconds, setSeconds] = useState(initialSeconds);
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (isActive && seconds > 0) {
			timer = setInterval(() => {
				setSeconds((prevSeconds) => prevSeconds - 1);
			}, 1000);
		} else if (seconds === 0) {
			setIsActive(false);
		}

		return () => clearInterval(timer);
	}, [isActive, seconds]);

	const startTimer = useCallback(() => {
		setSeconds(initialSeconds);
		setIsActive(true);
	}, [initialSeconds]);

	return { seconds, isActive, startTimer };
};
