import React from "react";
import { Box, Button, Modal } from "@mui/material";
import { fontSize, mainColor } from "theme/default";
import { useNavigate } from "react-router-dom";
import { Text } from "components";
import { iconWarning } from "assets/images";

interface IModalFail {
	open: boolean;
	onClose: () => void;
}

const ModalFail = ({ open, onClose }: IModalFail) => {
	const navigate = useNavigate();
	return (
		<Modal
			keepMounted
			open={open}
			sx={{
				backgroundColor: "#00000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={() => {}}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Box
				display={"flex"}
				alignItems={"center"}
				justifyContent={"space-between"}
				padding={"20px 10px"}
				flexDirection={"column"}
				height={"300px"}
				sx={{
					width: {
						xs: "90%",
						sm: "400px",
					},
				}}
				borderRadius={"10px"}
				bgcolor={mainColor.textMain}>
				<Text fontWeight={"600"} fontSize={fontSize.md}>
					Payment Unsuccessful
				</Text>

				<img src={iconWarning} alt="default" />
				<Box
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}>
					<Text fontWeight={"500"} fontSize={fontSize.sm} textAlign={"center"}>
						Sorry for any inconvenience caused.
					</Text>
					<Text
						fontSize={fontSize.xs}
						color={mainColor.warning}
						textAlign={"center"}>
						Error message: Transaction has been cancel.
					</Text>
				</Box>
				<Button
					onClick={() => {
						onClose();
						navigate("/pricing-plan");
					}}>
					Back to Plan
				</Button>
			</Box>
		</Modal>
	);
};

export default ModalFail;
