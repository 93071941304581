import React from "react";
import { Box, Link } from "@mui/material";
import { Text } from "components/Text";
import { defaultColorTheme } from "theme/default";

const Footer = () => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
				marginTop: "20px",
				flexWrap: "wrap",
			}}>
			<Link variant="body1" href="#" underline="hover">
				<Text useI18n variant="body1" color={defaultColorTheme.textMain}>
					©2024 StyleGen. All Rights Reserved |
				</Text>
			</Link>
			<Link variant="body1" href="#" underline="hover">
				<Text useI18n variant="body1" color={defaultColorTheme.textMain}>
					Terms and Condition |
				</Text>
			</Link>{" "}
			<Link variant="body1" href="#" underline="hover">
				<Text useI18n variant="body1" color={defaultColorTheme.textMain}>
					Privacy Policy
				</Text>
			</Link>
		</Box>
	);
};

export default Footer;
