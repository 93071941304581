import { Box, IconButton } from "@mui/material";
import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { Text } from "components/Text";
import { fontSize, mainColor } from "theme/default";
import { CloseOutlined, Image, PermMedia, Timer } from "@mui/icons-material";

interface ProcessResultProps {
	onClear?: () => void;
	initialImage?: string;
	description?: string;
	title?: string;
	isLoading?: boolean;
	valueProcess?: number;
	isStart?: boolean;
}

export const ProcessResult: React.FC<ProcessResultProps> = ({
	initialImage,
	description = "Your result will show here",
	onClear,
	title,
	isLoading,
	valueProcess,
	isStart,
}) => {
	const [previewUrl, setPreviewUrl] = useState<string | null>(null);
	const [isPreviewVisible, setIsPreviewVisible] = useState(false);

	useEffect(() => {
		if (initialImage) {
			typeof initialImage === "string" && setPreviewUrl(initialImage);
		}
	}, [initialImage]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (previewUrl) {
			setIsPreviewVisible(false);
			timer = setTimeout(() => {
				setIsPreviewVisible(true);
			}, 300);
		}

		return () => clearTimeout(timer);
	}, [previewUrl]);

	const handelClear = useCallback((event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		setPreviewUrl("");
		onClear?.();
	}, []);

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			bgcolor={mainColor.mainBlack}
			border={`5px solid ${mainColor.secondary}`}
			borderRadius="8px"
			width="100%"
			minHeight="250px"
			height={previewUrl ? "700px" : "500px"}
			sx={{ transition: "all 0.25s ease-in-out" }}
			position="relative"
			textAlign="center"
			padding={"50px"}
			className={`${isLoading ? "box-loading active" : undefined}`}
			overflow={"hidden"}>
			<Box
				position={"absolute"}
				top={"3px"}
				left={"3px"}
				zIndex={999}
				onClick={handelClear}
				component={"div"}
				bgcolor={mainColor.blackDark}
				padding={"2px 10px"}
				width={"auto"}
				borderRadius={"3px"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				gap={"10px"}
				sx={{
					cursor: "pointer",
				}}>
				<PermMedia style={{ color: "white", width: "15px" }} />
				<Text
					useI18n
					color={mainColor.textMain}
					fontSize={fontSize.xs}
					fontWeight={"400"}>
					{title}
				</Text>
			</Box>

			{previewUrl ? (
				<Box
					position={"absolute"}
					top={"3px"}
					right={"3px"}
					zIndex={99}
					onClick={handelClear}
					component={"div"}
					bgcolor={mainColor.blackDark}
					height={"20px"}
					width={"20px"}
					borderRadius={"3px"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					sx={{
						cursor: "pointer",
					}}>
					<IconButton>
						<CloseOutlined style={{ color: "white", width: "15px" }} />
					</IconButton>
				</Box>
			) : null}

			{isLoading && !isStart ? (
				<Box
					position={"absolute"}
					top={"3px"}
					right={"25px"}
					zIndex={999}
					onClick={handelClear}
					component={"div"}
					height={"20px"}
					borderRadius={"3px"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					gap={"5px"}
					marginTop={"2px"}
					sx={{
						cursor: "pointer",
						color: mainColor.textMain,
						fontSize: fontSize.sm,
						fontWeight: 400,
					}}>
					<Text
						sx={{
							color: mainColor.textMain,
							fontSize: fontSize.sm,
							fontWeight: 400,
						}}>
						Processing
					</Text>
					| {valueProcess?.toFixed(2) || 0}%
				</Box>
			) : null}

			{previewUrl ? (
				<Box position={"relative"} maxHeight={"100%"} maxWidth={"100%"}>
					<img
						src={previewUrl}
						alt="Preview"
						style={{
							maxWidth: "100%",
							maxHeight: "100%",
							opacity: isPreviewVisible ? 1 : 0,
							transition: "opacity 0.5s ease-in-out",
							borderRadius: "10px",
						}}
					/>
				</Box>
			) : (
				<Box
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}
					position={"absolute"}
					borderRadius={"5px"}
					height={"98%"}
					width={"99%"}
					zIndex={99}
					bgcolor={"black"}
					gap={"10px"}>
					{isStart ? (
						<Timer style={{ width: "40px", height: "40px" }} />
					) : (
						<Image style={{ width: "30px", height: "30px" }} />
					)}

					<Text
						useI18n
						variant="body1"
						sx={{
							color: mainColor.textMain,
							fontSize: fontSize.sm,
							fontWeight: 400,
						}}
						color="textSecondary">
						{isStart
							? "Please wait your progress will start soon !"
							: description}
					</Text>
				</Box>
			)}
		</Box>
	);
};
