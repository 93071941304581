import React from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import { useState } from "react";
import { ButtonCus, Text } from "components";
import { fontSize, mainColor } from "theme/default";
import { logoAuth } from "assets/images";
import CustomFormControlInput from "components/Inputs/InputOutLine";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { KEY_CONTEXT, loginSchema } from "utils";
import { FormLoginData, FormLoginSocial } from "types";
import useUserStore from "stores/userStore";
import { Icons } from "assets";
import { useMutation } from "@tanstack/react-query";
import { authService } from "services/authService";
import { useAuth } from "contexts";
import { useAlert, useKey } from "hooks";
import { useGoogleLogin } from "@react-oauth/google";
import { userService } from "services/userService";
import { useNavigate } from "react-router-dom";
import useDevice from "hooks/useDevice";

const LoginPage = () => {
	const { warn } = useAlert();
	const { setUser } = useUserStore();
	const { setKey } = useKey();
	const navigate = useNavigate();

	const { login } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid, isSubmitting },
	} = useForm<FormLoginData>({
		resolver: yupResolver(loginSchema),
		defaultValues: {
			email: "",
			password: "",
		},
	});

	const { deviceId, ipAddress, platForm } = useDevice();

	const [showPassword, setShowPassword] = useState(false);
	const loginMutation = useMutation({
		mutationFn: (formData: FormLoginData) => authService.login(formData),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				const { accessToken, refreshToken } = data.data;
				setKey(KEY_CONTEXT.AUTH_TOKEN, accessToken);
				setKey(KEY_CONTEXT.REFRESH_TOKEN, refreshToken);

				const rs = await userService.getProfile();
				if (rs?.data?.data) {
					setUser(rs.data?.data);
					navigate("/outfit");
					login();
					setIsLoading(false);
					reset();
				}
				return;
			}
			warn(data?.message || "Login failed");
			setIsLoading(false);
		},
		onError: () => {
			setIsLoading(false);
			warn("Login failed!");
		},
	});

	const onSubmit: SubmitHandler<FormLoginData> = (data) => {
		setIsLoading(true);
		const newData = {
			...data,
			ipAddress,
			deviceId,
			platForm,
		};
		loginMutation.mutate(newData);
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const loginSocialMutation = useMutation({
		mutationFn: (formData: FormLoginSocial) =>
			authService.loginGoogle(formData),
		onSuccess: ({ data }) => {
			if (data?.statusCode === 200) {
				const { accessToken, refreshToken } = data.data;
				setKey(KEY_CONTEXT.AUTH_TOKEN, accessToken);
				setKey(KEY_CONTEXT.REFRESH_TOKEN, refreshToken);
				navigate("/outfit");
				login();
				return;
			}
			warn(data?.message || "Login failed");
		},
		onError: () => {
			warn("Login failed!");
		},
	});

	const loginSocial = useGoogleLogin({
		onSuccess: ({ access_token }) => {
			try {
				loginSocialMutation.mutate({ idToken: access_token });
			} catch (error: unknown) {
				console.log("Login Social error:", error);
			}
		},
	});

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: mainColor.primary,
			}}>
			<Box
				bgcolor={mainColor.mainBlack}
				sx={{
					height: {
						xs: "760px",
						md: "860px",
					},
					borderRadius: { sm: "20px" },
					width: {
						xs: "100%",
						md: "500px",
						xl: "559px",
					},
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					padding: 3,
				}}>
				<Box mb={2}>
					<img src={logoAuth} alt="logo" />
				</Box>
				<Text
					variant="h2"
					gutterBottom
					useI18n
					sx={{ fontWeight: 500 }}
					color={mainColor.textMain}>
					Welcome back!
				</Text>
				<Text
					variant="h6"
					gutterBottom
					useI18n
					sx={{ fontWeight: 400 }}
					color={mainColor.textMain}>
					Please sign in to access your account
				</Text>
				<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
					<Controller
						name="email"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<CustomFormControlInput
								label="Email"
								value={field.value}
								setValue={field.onChange}
								error={!!errors.email}
								errorMessage={errors?.email?.message}
								fullWidth
								sx={{
									mt: 5,
									mb: 2.5,
								}}
							/>
						)}
					/>
					<Controller
						name="password"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<CustomFormControlInput
								label="Password"
								type={showPassword ? "text" : "password"}
								value={field.value}
								setValue={field.onChange}
								error={!!errors.password}
								errorMessage={errors.password?.message}
								fullWidth
								sx={{
									mb: 2.5,
								}}
								icon={<>{showPassword ? <Icons.Eye /> : <Icons.EyeHide />}</>}
								onClickIcon={handleClickShowPassword}
							/>
						)}
					/>
					<Box
						sx={{
							justifyContent: "flex-end",
							display: "flex",
						}}>
						<Link variant="body1" href="/forgot-password" underline="hover">
							<Text useI18n variant="body1" color={mainColor.textMain}>
								Forgot password?
							</Text>
						</Link>
					</Box>
					<ButtonCus
						sx={{
							mt: 2,
							backgroundColor: isValid
								? mainColor.secondary
								: mainColor.secondary,
						}}
						fullWidth
						type="submit"
						isLoading={isLoading}
						disabled={!isValid || isSubmitting}>
						<Text
							useI18n
							color={mainColor.textMain}
							fontSize={fontSize.sm}
							fontWeight={"600"}>
							Sign In
						</Text>
					</ButtonCus>
				</form>
				<Typography variant="body1" sx={{ mt: 2 }}>
					<Text useI18n color={mainColor.textMain}>
						Don’t have an account?
					</Text>
					<Link variant="body1" href="/register" underline="hover">
						{" "}
						<Text useI18n variant="body1" color={mainColor.secondary}>
							Sign up
						</Text>
					</Link>
				</Typography>

				{/* ==== LINE ==== */}
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: 1.5,
						my: 5,
						py: "14px",
						width: "100%",
					}}>
					<Box
						sx={{
							height: "1px",
							backgroundColor: mainColor.secondary,
							width: "100%",
						}}
					/>
					<Text useI18n variant="subtitle2" color={mainColor.secondary}>
						OR
					</Text>
					<Box
						sx={{
							height: "1px",
							backgroundColor: mainColor.secondary,
							width: "100%",
						}}
					/>
				</Box>

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						width: "100%",
						justifyContent: "center",
					}}>
					<Button
						variant="outlined"
						startIcon={<Icons.GoogleIcon />}
						fullWidth
						color="primary"
						sx={{
							height: "50px",
							maxWidth: "100%",
							borderRadius: "8px",
							border: "1px solid white",
							textTransform: "none",
						}}
						onClick={() => loginSocial()}>
						<Text useI18n variant="subtitle2" color={mainColor.textMain}>
							Continue with Google
						</Text>
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default LoginPage;
