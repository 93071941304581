import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import { Text } from "components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { formatMoney, QueryKeys } from "utils";
import { paymentService } from "services/paymentService";
import { fontSize, mainColor } from "theme/default";
import { CheckBox, TrendingFlat, West } from "@mui/icons-material";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useUser } from "hooks";
import {
	CreateOrderActions,
	OnApproveData,
	OnApproveActions,
	CreateOrderData,
} from "@paypal/paypal-js";
import { STATUS_PAYMENT } from "types";
import ModalSuccess from "./components/Payment/ModalSuccess";
import { TDetailBill } from "types/payment";
import ModalFail from "./components/Payment/ModalFail";

const PlanInfoPayment = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	const quantity = searchParams.get("quantity");
	const { getProfile } = useUser();
	const prevRef = useRef({ orderID: "" }).current;

	const { data } = useQuery({
		queryKey: [QueryKeys.GET_DETAIL_PLAN, id, quantity],
		queryFn: () => paymentService.detailPlan(id + ""),
	});
	const detailPlan = data?.data;
	const [statusPayment, setStatusPayment] = useState<
		STATUS_PAYMENT.ANNUAL | STATUS_PAYMENT.SUCCESS | STATUS_PAYMENT.FAIL
	>(STATUS_PAYMENT.ANNUAL);

	const [detailBill, setDetailBill] = useState<null | TDetailBill>(null);

	const createOrder = async (
		data: CreateOrderData,
		actions: CreateOrderActions,
	) => {
		console.log("========createOrder==========>", data, actions);
		try {
			const res = await paymentService.orderPaypal({
				reference_id: id + "",
				qty: quantity + "",
			});
			console.log("Res Create Order", res);
			prevRef.orderID = res?.data.id;
			return res?.data.id;
		} catch (error) {
			setStatusPayment(STATUS_PAYMENT.FAIL);
		}
	};

	const approve = async (data: OnApproveData, actions: OnApproveActions) => {
		console.log("=========approve=========>", data, actions);
		try {
			const res = await paymentService.approvePaypal({
				orderID: prevRef.orderID,
				quantity: quantity + "",
			});
			if (res?.data) {
				getProfile();
				setStatusPayment(STATUS_PAYMENT.SUCCESS);
				setDetailBill(res.data);
			}
		} catch (error) {
			setStatusPayment(STATUS_PAYMENT.FAIL);
		}
	};

	if (!detailPlan || !quantity || !id) return <></>;
	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"center"}
			alignItems={"center"}
			gap={"20px"}
			marginBottom={"20px"}
			paddingTop={"30px"}>
			<Box
				component={"div"}
				onClick={() => navigate("/pricing-plan")}
				width={"100%"}
				height={"30px"}
				marginBottom={"30px"}
				display={"flex"}
				sx={{ cursor: "pointer" }}
				alignItems={"center"}
				color={mainColor.textMain}
				gap={"10px"}>
				<West /> Back to all plans
			</Box>

			<Box
				padding={"20px"}
				display={"flex"}
				maxWidth={"600px"}
				width={"97%"}
				flexDirection={"column"}
				border={`1px solid ${mainColor.secondary}`}
				bgcolor={mainColor.mainBlack}
				borderRadius={"20px"}
				gap={"20px"}>
				<Box
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}
					marginBottom={"10px"}
					color={mainColor.textMain}>
					<Text fontSize={fontSize.xl} fontWeight={"700"} fontStyle={"italic"}>
						PLAN SUMMARY
					</Text>
				</Box>

				<Box
					justifyContent={"start"}
					gap={"15px"}
					alignItems={"center"}
					flexWrap={"wrap"}
					display={"flex"}>
					<Box>
						<Text
							color={mainColor.textMain}
							fontSize={fontSize.xl}
							fontWeight={"700"}>
							{formatMoney(detailPlan.price * +quantity)}
						</Text>
						<Text color={mainColor.textGray} fontSize={fontSize.sm}>
							{+quantity === 1 ? "/month" : "/year"}
						</Text>
					</Box>
					<TrendingFlat />
					<Box>
						<Text
							color={mainColor.secondary}
							fontSize={fontSize.xl}
							fontWeight={"700"}>
							{formatMoney(detailPlan.priceSale * +quantity)}
						</Text>
						<Text color={mainColor.textGray} fontSize={fontSize.sm}>
							{+quantity === 1 ? "/month" : "/year"}
						</Text>
					</Box>
				</Box>

				<Box display={"flex"} flexDirection={"column"}>
					<Box
						display={"flex"}
						alignItems={"center"}
						borderBottom={"1px solid #ccc"}
						paddingBottom={"10px"}
						marginBottom={"10px"}
						justifyContent={"space-between"}>
						<Text color={mainColor.textGray} useI18n fontSize={fontSize.md}>
							Tax
						</Text>
						<Text color={mainColor.textMain} fontSize={fontSize.md}>
							$0.00
						</Text>
					</Box>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<Text color={mainColor.textGray} useI18n fontSize={fontSize.xl}>
							Total due
						</Text>
						<Text color={mainColor.textMain} fontSize={fontSize.xl}>
							{formatMoney(detailPlan.priceSale * +quantity)}
						</Text>
					</Box>
				</Box>
				<Box
					component={"ul"}
					marginTop={"20px"}
					sx={{ listStyleType: "none" }}
					display={"flex"}
					flexDirection={"column"}>
					<Text
						color={mainColor.secondary}
						fontSize={fontSize.xl}
						fontWeight={"700"}>
						{detailPlan.packageName}
					</Text>
					<Text color={mainColor.textMain} fontSize={fontSize.sm}>
						{detailPlan.subTitle}
					</Text>
					{detailPlan?.description?.map((item, index) => {
						return (
							<Box
								component={"li"}
								marginTop={"15px"}
								color={mainColor.textMain}
								fontSize={fontSize.xs}
								display={"flex"}
								alignItems={"center"}
								gap={"20px"}
								key={index}>
								<CheckBox
									sx={{
										width: "20px",
										stroke: mainColor.textPurple,
										strokeWidth: 2,
									}}
								/>
								{item}
							</Box>
						);
					})}
				</Box>
				<Text
					marginTop={"30px"}
					color={mainColor.secondary}
					fontSize={fontSize.xs}
					fontStyle={"italic"}
					maxWidth={"1000px"}>
					* By providing your payment information and clicking “Confirm
					Purchase/Upgrade Plan”, you agree you will be charged the amount
					listed above and your subscription will automatically renew untill you
					cancel (price subject to change). Cancel anytime via Account Settings
					or Customer Support. You also agree to the Terms of Service and
					Privacy Policy.
				</Text>
				<Box display={"flex"} flexDirection={"column"} gap={"20px"}>
					<Text
						color={mainColor.textMain}
						fontStyle={"italic"}
						fontSize={fontSize.sm}>
						* Payment Method:
					</Text>
					<Box width={"100%"} margin={"0 auto"}>
						<PayPalButtons createOrder={createOrder} onApprove={approve} />
					</Box>
				</Box>
			</Box>
			{/* ======== MODALS STATUS ========= */}
			<ModalSuccess
				open={statusPayment === STATUS_PAYMENT.SUCCESS}
				onClose={() => {
					setDetailBill(null);
					setStatusPayment(STATUS_PAYMENT.ANNUAL);
				}}
				info={detailBill}
			/>
			<ModalFail
				open={statusPayment === STATUS_PAYMENT.FAIL}
				onClose={() => {
					setStatusPayment(STATUS_PAYMENT.ANNUAL);
				}}
			/>
			{/* ======== END MODALS STATUS ========= */}
		</Box>
	);
};

export default PlanInfoPayment;
