export const KEY_CONTEXT = {
  AUTH_TOKEN: "authToken",
  REFRESH_TOKEN: "refreshToken",
  LANGUAGE: "language",
  THEME_MODE: "themeMode",
  USER: "user",
};


export enum EnumValueDataType {
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  Object = 'Object',
  Array = 'Array',
  Null = 'Null',
  Undefined = 'Undefined',
  Function = 'Function',
}

export const QueryKeys = {
  PROFILE: "profile",
  MODEL_EXAMPLES: "models_examples",
  CLOTHES_EXAMPLES: "clothes_examples",
  MODELS: "models",
  GALLERY_LIST:"galleryList",
  GET_CLOTHES:"getClothes",
  GET_PLAN:'getPlan',
  GET_DETAIL_PLAN:'c'
}

export const initialOptionsPaypal = {
	clientId: `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
	currency: "USD",
	intent: "capture",
};