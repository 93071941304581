import { Box, IconButton } from "@mui/material";
import React, {
	MouseEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { Text } from "components/Text";
import { Icons } from "assets";
import { fontSize, mainColor } from "theme/default";
import { CloseOutlined, Download, PermMedia } from "@mui/icons-material";
import { useDrop } from "react-dnd";

interface ImageUploadProps {
	onFileSelect?: (file: File | string) => void;
	onClear?: () => void;
	initialImage?: string;
	justView?: boolean;
	description?: string;
	title?: string;
	dropType: string;
	valueCategory?: string;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
	onFileSelect,
	initialImage,
	justView = false,
	description = "Drop or upload your image",
	onClear,
	title,
	dropType,
}) => {
	const [previewUrl, setPreviewUrl] = useState<string | null>(null);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [isPreviewVisible, setIsPreviewVisible] = useState(false);

	useEffect(() => {
		if (initialImage) {
			typeof initialImage === "string" && setPreviewUrl(initialImage);
		}
	}, [initialImage]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (previewUrl) {
			setIsPreviewVisible(false);
			timer = setTimeout(() => {
				setIsPreviewVisible(true);
			}, 300);
		}

		return () => clearTimeout(timer);
	}, [previewUrl]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			onFileSelect?.(file);
			const url = URL.createObjectURL(file);
			setPreviewUrl(url);
			// Clean up the URL when the component unmounts
			return () => {
				URL.revokeObjectURL(url);
			};
		}
	};

	const handleClick = () => {
		if (fileInputRef.current && !justView) {
			fileInputRef.current.click();
		}
	};

	const handelClear = useCallback((event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		setPreviewUrl("");
		onFileSelect?.("");
		onClear?.();
	}, []);

	// useEffect(() => {
	// 	if (valueCategory) {
	// 		setPreviewUrl("");
	// 		onFileSelect?.("");
	// 		onClear?.();
	// 	}
	// }, [valueCategory]);

	// ============= HANDLE DROP ==============
	const [{ canDrop, isOver }, drop] = useDrop(() => ({
		accept: dropType,
		drop: () => ({ name: "Dustbin" }),
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	}));
	const isActive = canDrop && isOver;

	let backgroundColor = mainColor.mainBlack;
	if (isActive) {
		backgroundColor = "#0a3f63";
	} else if (canDrop) {
		backgroundColor = "#5adbfc59";
	}

	return (
		<Box
			ref={drop}
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			bgcolor={backgroundColor}
			border={`2px dashed ${mainColor.secondary}`}
			borderRadius="8px"
			width="100%"
			minHeight="250px"
			height={previewUrl ? "500px" : "250px"}
			sx={{ transition: "all 0.25s ease-in-out" }}
			position="relative"
			textAlign="center"
			padding={"30px"}
			onClick={handleClick}>
			<input
				type="file"
				ref={fileInputRef}
				style={{ display: "none" }}
				accept=".png, .jpg, .jpeg, .webp"
				onChange={handleFileChange}
			/>

			<Box
				position={"absolute"}
				top={"3px"}
				left={"3px"}
				onClick={handelClear}
				component={"div"}
				bgcolor={mainColor.blackDark}
				padding={"2px 10px"}
				width={"auto"}
				borderRadius={"3px"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				gap={"10px"}
				sx={{
					cursor: "pointer",
				}}>
				<PermMedia style={{ color: "white", width: "15px" }} />
				<Text
					useI18n
					color={mainColor.textMain}
					fontSize={fontSize.xs}
					fontWeight={"400"}>
					{title}
				</Text>
			</Box>

			{previewUrl && (
				<>
					<Box
						position={"absolute"}
						top={"3px"}
						right={"3px"}
						onClick={handelClear}
						component={"div"}
						bgcolor={mainColor.blackDark}
						height={"30px"}
						width={"30px"}
						borderRadius={"3px"}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						sx={{
							cursor: "pointer",
						}}>
						<IconButton>
							<CloseOutlined style={{ color: "white", width: "15px" }} />
						</IconButton>
					</Box>
					<Box
						position={"absolute"}
						top={"3px"}
						right={"40px"}
						component={"a"}
						href={previewUrl + ""}
						download={"downloaded_image.jpg"}
						bgcolor={mainColor.blackDark}
						onClick={(e) => e.stopPropagation()}
						height={"30px"}
						width={"30px"}
						borderRadius={"3px"}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						sx={{
							cursor: "pointer",
						}}>
						<Download style={{ color: "white", width: "15px" }} />
					</Box>
				</>
			)}

			{previewUrl ? (
				<Box position={"relative"} maxHeight={"100%"} maxWidth={"100%"}>
					<img
						src={previewUrl}
						alt="Preview"
						style={{
							maxWidth: "100%",
							maxHeight: "100%",
							opacity: isPreviewVisible ? 1 : 0,
							transition: "opacity 0.5s ease-in-out",
							borderRadius: "10px",
						}}
					/>
				</Box>
			) : (
				<Box
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}
					gap={"10px"}>
					{Icons.Upload()}
					<Text
						useI18n
						variant="body1"
						sx={{
							color: mainColor.textMain,
							fontSize: fontSize.xs,
							fontWeight: 400,
						}}
						color="textSecondary">
						{description}
					</Text>
				</Box>
			)}
		</Box>
	);
};
