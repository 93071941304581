import React, { ReactElement, useId } from "react";
import { styled } from "@mui/system";
import { FormControl, IconButton, Theme, SxProps, Box } from "@mui/material";
import { Text } from "components/Text";
import { defaultColorTheme, fontSize } from "theme/default";

const CustomFormControl = styled(FormControl)<{
	warning?: boolean;
	error?: boolean;
}>(({ warning, error }) => ({
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: error
				? defaultColorTheme.warning
				: warning
					? defaultColorTheme.warning
					: defaultColorTheme.textMain, // Outline color based on error or warning
		},
		"&:hover fieldset": {
			borderColor: error
				? defaultColorTheme.warning
				: warning
					? defaultColorTheme.warning
					: defaultColorTheme.textMain, // Outline color on hover
		},
		"&.Mui-focused fieldset": {
			borderColor: error
				? defaultColorTheme.warning
				: warning
					? defaultColorTheme.warning
					: defaultColorTheme.textMain, // Outline color when focused
		},
	},
	"& .MuiInputLabel-root": {
		color: error
			? defaultColorTheme.warning
			: warning
				? defaultColorTheme.warning
				: defaultColorTheme.textMain,
		"&.Mui-focused": {
			color: error
				? defaultColorTheme.warning
				: warning
					? defaultColorTheme.warning
					: defaultColorTheme.textMain,
		},
	},
}));

interface CustomFormControlInputProps {
	warning?: boolean;
	error?: boolean;
	label: string;
	value: string;
	errorMessage?: string;
	setValue?: (value: string) => void;
	type?: string;
	fullWidth?: boolean;
	sx?: SxProps<Theme>;
	icon?: ReactElement;
	onClickIcon?: () => void;
	placeholder?: string;
}

const CustomFormControlInput: React.FC<CustomFormControlInputProps> = ({
	warning,
	error,
	label,
	value,
	setValue,
	errorMessage,
	type = "text",
	fullWidth,
	sx,
	icon,
	onClickIcon,
}) => {
	const id = useId();

	return (
		<CustomFormControl
			variant="outlined"
			warning={warning}
			error={error}
			fullWidth={fullWidth}
			sx={sx}>
			<input
				autoComplete="off"
				id={id}
				type={type}
				value={value}
				onChange={(e) => setValue?.(e.target.value)}
				placeholder={label}
				style={{
					backgroundColor: "transparent",
					outline: "none",
					height: "50px",
					padding: "0 20px",
					borderRadius: "5px",
					border: "1px solid #ffffff",
					color: "white",
					fontSize: fontSize.sm,
				}}
			/>
			{errorMessage && (
				<Text
					marginTop={"5px"}
					useI18n
					color={
						error
							? defaultColorTheme.warning
							: warning
								? defaultColorTheme.warning
								: defaultColorTheme.textMain
					}>
					{errorMessage}
				</Text>
			)}
			{icon && (
				<Box position={"absolute"} right="5px" top={"6px"}>
					<IconButton onClick={onClickIcon} sx={{ padding: 1 }}>
						{icon}
					</IconButton>
				</Box>
			)}
		</CustomFormControl>
	);
};

export default CustomFormControlInput;
