import React from "react";
import {
	Avatar,
	Box,
	Card,
	CardContent,
	Divider,
	Grid,
	Modal,
	Typography,
} from "@mui/material";
import { fontSize, mainColor } from "theme/default";
import { formatMoney } from "utils";
import useUserStore from "stores/userStore";
import { avtDf } from "assets/images";

interface IModalProfile {
	open: boolean;
	onClose: () => void;
}

const ModalProfile = ({ open, onClose }: IModalProfile) => {
	const { user } = useUserStore();

	return (
		<Modal
			keepMounted
			open={open}
			sx={{
				backgroundColor: "#00000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onClose}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Card
				sx={{
					width: 600,
					margin: "0 auto",
					mt: 5,
					border: `3px solid ${mainColor.secondary}`,
					backgroundColor: "#ffffff",
					borderRadius: "10px",
				}}>
				<CardContent>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<Avatar
								alt={user?.userName}
								src={user?.avatar?.length ? user?.avatar : avtDf}
								sx={{ width: 60, height: 60 }}
							/>
						</Grid>
						<Grid item>
							<Typography variant="h3">{user?.userName}</Typography>
							<Typography color="textSecondary">{user?.email}</Typography>
						</Grid>
					</Grid>

					<Divider sx={{ my: 2, bgcolor: mainColor.secondary }} />

					<Box display={"flex"} flexDirection={"column"} gap={"20px"}>
						<Typography variant="body1" fontSize={fontSize.sm}>
							<strong>ID:</strong> {user?.id}
						</Typography>
						<Typography variant="body1" fontSize={fontSize.sm}>
							<strong>Plan:</strong> {user?.plan}
						</Typography>
						<Typography variant="body1" fontSize={fontSize.sm}>
							<strong>Credits:</strong> {user?.credits}
						</Typography>
						<Typography variant="body1" fontSize={fontSize.sm}>
							<strong>Total Bill: </strong>
							{formatMoney(user?.totalBill || 0)}
						</Typography>
					</Box>
				</CardContent>
			</Card>
		</Modal>
	);
};

export default ModalProfile;
