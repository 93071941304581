import React from "react";
import { Box } from "@mui/material";
import { useState } from "react";
import { ButtonCus, Text } from "components";
import { fontSize, mainColor } from "theme/default";
import { logoAuth } from "assets/images";
import CustomFormControlInput from "components/Inputs/InputOutLine";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassSchema } from "utils";
import { FormResetPassword } from "types";
import useUserStore from "stores/userStore";
import { Icons } from "assets";
import { useMutation } from "@tanstack/react-query";
import { authService } from "services/authService";
import { useAlert } from "hooks";
import { useNavigate } from "react-router-dom";

const ResetPasswordPage = () => {
	const navigate = useNavigate();
	const { success, warn } = useAlert();
	const { uid } = useUserStore();
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid, isSubmitting },
	} = useForm<FormResetPassword>({
		resolver: yupResolver(resetPassSchema),
		defaultValues: {
			newPassword: "",
			confirmPassword: "",
		},
	});
	const [showPassword, setShowPassword] = useState(false);
	const [showCpw, setShowCpw] = useState(false);

	const resetPassMutation = useMutation({
		mutationFn: (formData: FormResetPassword) =>
			authService.changePassword({ newPassword: formData.newPassword, uid }),
		onSuccess: ({ data }) => {
			if (data?.statusCode === 200) {
				success("Change password successful! Please login again");
				navigate("/login");
				return;
			}
			warn(data?.message || "Change password failed");
		},
		onError: () => {
			warn("Change password failed!");
		},
	});

	const onSubmit: SubmitHandler<FormResetPassword> = (data) => {
		resetPassMutation.mutate(data);
		reset();
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleClickShowPcw = () => setShowCpw(!showCpw);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: mainColor.primary,
			}}>
			<Box
				bgcolor={mainColor.mainBlack}
				width={"559px"}
				height={"860px"}
				borderRadius={"32px"}
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					padding: 3,
				}}>
				<Box mb={5}>
					<img src={logoAuth} alt="logo" />
				</Box>
				<Text
					variant="h2"
					gutterBottom
					useI18n
					sx={{ fontWeight: 500 }}
					color={mainColor.textMain}>
					Set new password
				</Text>
				<Text
					variant="h6"
					gutterBottom
					useI18n
					sx={{ fontWeight: 400, marginBottom: "30px" }}
					color={mainColor.textMain}>
					Create a secure new password.
				</Text>
				<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
					<Controller
						name="newPassword"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<CustomFormControlInput
								label="Password"
								type={showPassword ? "text" : "password"}
								value={field.value}
								setValue={field.onChange}
								error={!!errors.newPassword}
								errorMessage={errors.newPassword?.message}
								fullWidth
								placeholder="New Password"
								sx={{
									mb: 2.5,
								}}
								icon={<>{showPassword ? <Icons.Eye /> : <Icons.EyeHide />}</>}
								onClickIcon={handleClickShowPassword}
							/>
						)}
					/>
					<Controller
						name="confirmPassword"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<CustomFormControlInput
								label="Confirm Password"
								type={showCpw ? "text" : "password"}
								value={field.value}
								setValue={field.onChange}
								error={!!errors.confirmPassword}
								errorMessage={errors.confirmPassword?.message}
								fullWidth
								sx={{
									mb: 2.5,
								}}
								icon={<>{showCpw ? <Icons.Eye /> : <Icons.EyeHide />}</>}
								onClickIcon={handleClickShowPcw}
							/>
						)}
					/>
					<ButtonCus
						sx={{
							mt: 2,
							backgroundColor: isValid
								? mainColor.secondary
								: mainColor.secondary,
						}}
						fullWidth
						type="submit"
						disabled={!isValid || isSubmitting}>
						<Text
							useI18n
							color={mainColor.textMain}
							fontSize={fontSize.sm}
							fontWeight={"600"}>
							Save password
						</Text>
					</ButtonCus>
				</form>
			</Box>
		</Box>
	);
};

export default ResetPasswordPage;
