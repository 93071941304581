import axios, {
	AxiosInstance,
	AxiosResponse,
	AxiosError,
	InternalAxiosRequestConfig,
	AxiosHeaders,
} from "axios";
import { authService } from "./authService";
import { useKey } from "hooks";
import { KEY_CONTEXT } from "utils";

const axiosInstance: AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URI,
	headers: {
		"Content-Type": "application/json",
	},
});

// Request Interceptor
axiosInstance.interceptors.request.use(
	(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
		const { getKey } = useKey();
		const token = getKey(KEY_CONTEXT.AUTH_TOKEN);
		if (token) {
			config.headers = config.headers ?? new AxiosHeaders();
			(config.headers as AxiosHeaders).set(
				"Authorization",
				`Bearer ${token}`,
			);
		}
		return config;
	},
	(error: AxiosError) => {
		return Promise.reject(error);
	},
);

// Response Interceptor
axiosInstance.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	async (error: AxiosError) => {
		console.log("🚀 ~ error:", error?.response);
		const originalRequest = error.config as InternalAxiosRequestConfig & {
			_retry?: boolean;
		};

		if (
			error.response?.status === 401 ||
			(error.response?.status === 403 && !originalRequest._retry)
		) {
			originalRequest._retry = true;

			const { getKey, setKey, removeAllKeys } = useKey();

			const rfToken = getKey(KEY_CONTEXT.REFRESH_TOKEN);
			try {
				const newToken = (await authService.refreshToken(`${rfToken}`)) || "";
				const {accessToken, refreshToken} = newToken.data.data;
				console.log("🚀 ~ accessToken, refreshToken:", accessToken, refreshToken)
				if (accessToken && refreshToken) {
					setKey(KEY_CONTEXT.AUTH_TOKEN, accessToken);
					setKey(KEY_CONTEXT.REFRESH_TOKEN, refreshToken);

					// Set new token in default headers
					axiosInstance.defaults.headers.common["Authorization"] =
						`Bearer ${newToken}`;
					return axiosInstance(originalRequest);
				}
			} catch (refreshError) {
				removeAllKeys("deviceId");
				return Promise.reject(refreshError);
			}
		}
		return Promise.reject(error);
	},
);

export default axiosInstance;
