import React, { useState } from "react";
import { Box } from "@mui/material";
import { ImageUpload, PaginationCommon } from "components";
import { useModel } from "hooks";
import useOutfitStore from "stores/outfitStore";
import { EDropType, IModelBodyProps, TParams } from "types";
import ListImage from "./ImageList";
import Heading from "components/Heading/Heading";

export const ModelBody: React.FC<IModelBodyProps> = ({
	paramsGallery,
	setParamsGallery,
	dataGallery,
	isLoadingGallery,
}) => {
	// ======================= HOOKS ==============================
	const {
		setSelectedFileModel,
		modelImage,
		setModelImage,
		selectedFileModel,
		setSelectedFileGallery,
		selectedFileGallery,
	} = useOutfitStore();
	const { useGetModels } = useModel();

	// ======================== STATE ========================
	const [paramsModelsFemale, setParamsModalFemale] = useState<TParams>({
		page: 1,
		limit: 5,
		typeModel: "FEMALE",
	});
	const { data: dataModelFemale, isLoading: isLoadingFemale } =
		useGetModels(paramsModelsFemale);

	const [paramsModelsMale, setParamsModalMale] = useState<TParams>({
		page: 1,
		limit: 5,
		typeModel: "MALE",
	});
	const { data: dataModelMale, isLoading: isLoadingMale } =
		useGetModels(paramsModelsMale);

	return (
		<Box>
			<Box>
				<ImageUpload
					onFileSelect={(file) => {
						setSelectedFileGallery(null);
						setSelectedFileModel(file);
					}}
					onClear={() => setModelImage("")}
					initialImage={modelImage as string}
					title="Your Entire Body"
					dropType={EDropType.BODY}
				/>
			</Box>

			<Box
				sx={{
					mt: 2,
					height: {
						xs: "160px",
						xl: "260px",
					},
				}}>
				<Heading title="Your gallery" />
				<ListImage
					isLoading={isLoadingGallery}
					images={dataGallery?.data}
					selectedImage={selectedFileGallery as string}
					setSelectedImage={(it) => {
						setSelectedFileModel(null);
						setModelImage(it.image as string);
						setSelectedFileGallery(it.id as string);
					}}
					dropType={EDropType.BODY}
				/>
				<Box display={"flex"} alignItems={"center"} justifyContent={"end"}>
					<PaginationCommon
						total={dataGallery?.pagination?.totalPage as number}
						onChange={(p: number) => {
							setParamsGallery?.({ ...paramsGallery, page: p });
						}}
					/>
				</Box>
			</Box>

			<Box
				sx={{
					mt: 2,
					height: {
						xs: "160px",
						xl: "260px",
					},
				}}>
				<Heading title="Female Body Shape" />
				<ListImage
					isLoading={isLoadingFemale}
					images={dataModelFemale?.data}
					selectedImage={selectedFileModel as string}
					setSelectedImage={(it) => {
						setSelectedFileModel(it.id as string);
						setModelImage(it.image as string);
						setSelectedFileGallery(null);
					}}
					dropType={EDropType.BODY}
				/>
				<Box display={"flex"} alignItems={"center"} justifyContent={"end"}>
					<PaginationCommon
						total={dataModelFemale?.pagination?.totalPage as number}
						onChange={(p: number) => {
							setParamsModalFemale({ ...paramsModelsFemale, page: p });
						}}
					/>
				</Box>
			</Box>

			<Box sx={{ mt: 3 }}>
				<Heading title="Male Body Shape" />
				<ListImage
					isLoading={isLoadingMale}
					images={dataModelMale?.data}
					selectedImage={selectedFileModel as string}
					setSelectedImage={(it) => {
						setSelectedFileModel(it.id as string);
						setModelImage(it.image as string);
						setSelectedFileGallery(null);
					}}
					dropType={EDropType.BODY}
				/>
				<Box display={"flex"} alignItems={"center"} justifyContent={"end"}>
					<PaginationCommon
						total={dataModelMale?.pagination?.totalPage as number}
						onChange={(p: number) => {
							setParamsModalMale({ ...paramsModelsMale, page: p });
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};
