import { END_POINT } from "utils";
import axiosInstance from "./axios";
import { IModelType, TParams, TResponse } from "types";

export const clothesService = {
	getClothesExample: async (params: TParams) : Promise<TResponse<IModelType[]>> => {
		const { page, limit, ...restParams } = params;
		// Construct the additional query string parameters
		const queryParams = new URLSearchParams(
			restParams as Record<string, string>,
		).toString();

		// Construct the full URL
		const url = `${END_POINT.CLOTHES.GET_EXAMPLE}?page=${page}&limit=${limit}&${queryParams}`;
		const data = await axiosInstance.get(url);
		return data?.data
	},
};
