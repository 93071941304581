import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { ButtonCus, Text } from "components";
import { fontSize, mainColor } from "theme/default";
import { logoAuth } from "assets/images";
import CustomFormControlInput from "components/Inputs/InputOutLine";
import { Controller, Resolver, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dynamicSchema } from "utils";
import {
	ErrorOtpForm,
	ErrorOtpMessage,
	ForgotForm,
	OTPForm,
	TypeSendMailOtp,
} from "types";
import { authService } from "services/authService";
import { useAlert, useTimer } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { userService } from "services/userService";

const VerifyEmail = () => {
	const { warn, success } = useAlert();
	const navigate = useNavigate();
	const {
		control,
		handleSubmit,
		formState: { errors, isValid, isSubmitting },
	} = useForm<ForgotForm | OTPForm>({
		resolver: yupResolver(dynamicSchema(true)) as unknown as Resolver<
			ForgotForm | OTPForm
		>,
		defaultValues: {
			otp: "",
		},
	});

	const { seconds, isActive, startTimer } = useTimer(300);
	const location = useLocation();

	const verifyOtpMutation = useMutation({
		mutationFn: (formData: OTPForm) => authService.verifyEmail(formData.otp),
		onSuccess: ({ data }) => {
			if (data?.statusCode === 200) {
				success(data?.message);
				navigate("/login");
				return;
			}
			warn(data?.message || "Failed to send otp");
		},
		onError: () => {
			warn("Change failed!");
		},
	});

	const sendOtpMutation = useMutation({
		mutationFn: (formData: ForgotForm) =>
			userService.getOtpViaMail(formData.email, TypeSendMailOtp.REGISTER),
		onSuccess: ({ data }) => {
			if (data?.statusCode === 200) {
				success("Enter OTP is sent to your email");
				startTimer();
				return;
			}
			warn(data?.message || "Failed to send otp");
		},
		onError: () => {
			warn("Change failed!");
		},
	});

	const onSubmit: SubmitHandler<ForgotForm | OTPForm> = (data) => {
		verifyOtpMutation.mutate(data as OTPForm);
	};

	useEffect(() => {
		startTimer();
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: mainColor.primary,
			}}>
			<Box
				bgcolor={mainColor.mainBlack}
				width={"559px"}
				height={"860px"}
				borderRadius={"32px"}
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					padding: 3,
				}}>
				<Box mb={5}>
					<img src={logoAuth} alt="logo" />
				</Box>
				<Text
					variant="h2"
					gutterBottom
					useI18n
					sx={{ fontWeight: 500 }}
					color={mainColor.textMain}>
					Verify email
				</Text>
				<Text
					variant="h6"
					gutterBottom
					useI18n
					sx={{ fontWeight: 400 }}
					color={mainColor.textMain}>
					Enter OTP to verify your email.
				</Text>
				<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
					<Controller
						name="otp"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<CustomFormControlInput
								label="OTP"
								value={`${field.value}`}
								setValue={field.onChange}
								error={!!(errors as ErrorOtpForm)?.otp}
								errorMessage={(errors as ErrorOtpMessage)?.otp?.message}
								fullWidth
								sx={{
									mt: 5,
									mb: 2.5,
								}}
							/>
						)}
					/>
					<ButtonCus
						sx={{
							mt: 2,
							backgroundColor: isValid
								? mainColor.secondary
								: mainColor.secondary,
						}}
						fullWidth
						type="submit"
						disabled={!isValid || isSubmitting}>
						<Text
							useI18n
							color={mainColor.textMain}
							fontSize={fontSize.sm}
							fontWeight={"600"}>
							Verify
						</Text>
					</ButtonCus>
				</form>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
					}}>
					<Text useI18n color={mainColor.textMain}>
						Didn’t get any email?
					</Text>
					<ButtonCus
						sx={{
							width: "auto",
							p: 0,
							minWidth: "auto",
							ml: 0.5,
						}}
						variant="text"
						fullWidth
						onClick={() => {
							if (!isActive) {
								sendOtpMutation.mutate({
									email: location.state,
								});
								startTimer();
							}
						}}
						disabled={isActive}>
						<Text useI18n variant="body1" color={mainColor.secondary}>
							Resend
						</Text>
						{isActive ? (
							<Text useI18n variant="body1" color={mainColor.textMain}>
								{` (${seconds}s)`}
							</Text>
						) : (
							""
						)}
					</ButtonCus>
				</Box>
			</Box>
		</Box>
	);
};

export default VerifyEmail;
