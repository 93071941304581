import { IColorDefault, IFontSize } from "types/theme";

export const defaultColorTheme: IColorDefault = {
    primary: '#1076BB',
    secondary: '#21303f',
    warning: '#FFCC00',
    success: '#3CD4A0',
    info: '#9013FE',
    error: '#750606',
    grey: '#ECECEC',
    mainBlack: '#000000',
    textMain: '#808080',
    textPurple: '#21065D',
    textD8: '#D8D8D8'
  };

export const mainColor: IColorDefault = {
    primary: '#1F1F1F',
    secondary: '#5ADBFC',
    warning: '#FFCC00',
    success: '#3CD4A0',
    info: '#9013FE',
    error: '#750606',
    grey: '#ECECEC',
    mainBlack: '#000000',
    blackDark:"#1F1F24",
    textMain: '#FFFFFF',
    textGray: '#B3B6B6',
    borderColor:"#3C3C3C",
}

export const fontSize: IFontSize = {
    xs: "14px",
    sm:"16px",
    md: "20px",
    xl: "25px"
}