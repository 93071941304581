import React, { FC, useEffect } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useNavigate,
} from "react-router-dom";
import { useAuth } from "contexts";
import { privateRoutes, publicRoutes, RouterType } from "routes";
import LoginPage from "pages/LoginPage";
import { ThemeProvider } from "@mui/material/styles";
import theme from "theme/theme";
import { ToastContainer } from "react-toastify";
import i18next from "i18next";
import useUserStore from "stores/userStore";
import { MainLayout, SubLayout } from "layouts";
import useDevice from "hooks/useDevice";
import { ModalGlobal } from "components/ModalGlobal";
import useLoginOneDevice from "hooks/useLoginOneDevice";
import { useUser } from "hooks";

const RequireAuth: FC<{ children: React.ReactElement }> = ({ children }) => {
	const { isAuthenticated } = useAuth();
	const navigate = useNavigate();
	if (!isAuthenticated) {
		navigate("/login");
	}
	return children;
};

const App: React.FC = () => {
	const { loginWithMerchantLink, setOrigin } = useUser();
	const { deviceId } = useDevice();
	const { languages } = useUserStore();
	const { login } = useAuth()
	// useDetectMultiTab();
	useLoginOneDevice(deviceId);

	useEffect(() => {
		if (languages.value) {
			i18next.changeLanguage(languages.value);
		}
	}, [languages.value]);

	useEffect(() => {
		window.onmessage = async function (event) {
			const url = window.location.origin;
			if (url !== event.origin && event.data === 'cross-domain') {
				console.log("🚀 ~ useEffect ~ event:", event.data === 'cross-domain', event.origin, window.location.origin)
				loginWithMerchantLink(event.origin, (r) => {
					if (r) {
						setOrigin(event)
						login();
						window.location.href = '/outfit'
					}
				})
			}

		};
	}, []);

	return (

		<ThemeProvider theme={theme}>
			<ToastContainer />
			<ModalGlobal />
			<Router>
				<Routes>
					{publicRoutes.map((route: RouterType, index) => {
						const Layout = route.layout || SubLayout;
						const Component = route.component;
						return (
							<Route
								key={index}
								path={route.path}
								element={
									<Layout>
										<Component />
									</Layout>
								}
							/>
						);
					})}
					{privateRoutes.map((route: RouterType, index) => {
						const Layout = route.layout || MainLayout;
						const Component = route.component;
						return (
							<Route
								key={`${index}-${route.path}`}
								path={route.path}
								element={
									<RequireAuth>
										<Layout>
											<Component />
										</Layout>
									</RequireAuth>
								}
							/>
						);
					})}
					<Route path="*" element={<LoginPage />} />
				</Routes>
			</Router>
		</ThemeProvider>
	)
};

export default App;
