import { FC } from "react";
import { MainLayout, SubLayout } from "layouts";
import LoginPage from "pages/LoginPage";
import RegisterPage from "pages/RegisterPage";
import ForgotPage from "pages/ForgotPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import HomePage from "pages/HomePage";
import VerifyEmail from "pages/VerifyEmail";
import PricingPlan from "pages/PricingPlan";
import PlanInfoPayment from "pages/PlanInfoPayment";
import ProfilePage from "pages/ProfilePage";


type TComponent =  FC<{ children: string | JSX.Element | JSX.Element[] | JSX.Element }>

export interface RouterType {
	path: string;
	component: FC;
	layout: TComponent
}

const publicRoutes: RouterType[] = [
	{
		path: "/login",
		component: LoginPage,
		layout: SubLayout as TComponent,
	},
	{
		path: "/register",
		component: RegisterPage,
		layout: SubLayout as TComponent,
	},
    {
		path: "/forgot-password",
		component: ForgotPage,
		layout: SubLayout as TComponent,
	},
	{
		path: "/reset-password",
		component: ResetPasswordPage,
		layout: SubLayout as TComponent,
	},
	{
		path: "/verify-email",
		component: VerifyEmail,
		layout: SubLayout as TComponent,
	},
];

const privateRoutes: RouterType[] = [
	{
		path: "/",
		component: HomePage,
		layout: MainLayout as TComponent,
	},
	{
		path: "/outfit",
		component: HomePage,
		layout: MainLayout as TComponent,
	},
	{
		path: "/face-swap",
		component: HomePage,
		layout: MainLayout as TComponent,
	},
	{
		path: "/hair-swap",
		component: HomePage,
		layout: MainLayout as TComponent,
	},
	{
		path: "/pricing-plan",
		component: PricingPlan,
		layout: MainLayout as TComponent,
	},
	{
		path: "/info-payment",
		component: PlanInfoPayment,
		layout: MainLayout as TComponent,
	},
	{
		path: "/profile",
		component: ProfilePage,
		layout: MainLayout as TComponent,
	},
];

export { publicRoutes, privateRoutes };
