import React, { createContext, useContext, useEffect } from "react";
import { AuthContextType } from "types";
import { useKey } from "hooks";
import { KEY_CONTEXT } from "utils";
import { userService } from "services/userService";
import useOutfitStore from "stores/outfitStore";
import useUserStore from "stores/userStore";

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { getKey, removeAllKeys } = useKey();
	const { reset: resetOutfitStore } = useOutfitStore();
	const { isAuthenticated, setIsAuthenticated, setOrigin, origin } = useUserStore();

	const login = () => setIsAuthenticated(true);

	useEffect(() => {
		const access_token = getKey(KEY_CONTEXT.AUTH_TOKEN);
		if (access_token) {
			setIsAuthenticated(true);
		}
	}, []);

	const logout = async () => {
		await userService.logout();
		removeAllKeys("deviceId");
		resetOutfitStore();
		setIsAuthenticated(false);
		origin?.source?.postMessage?.('inner');
		setOrigin(null)
	};

	return (
		<AuthContext.Provider value={{ isAuthenticated, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
