import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider, SocketProvider } from "contexts";
import { CssBaseline } from "@mui/material";
import "./i18n";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { initialOptionsPaypal } from "utils";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);
root.render(
	<QueryClientProvider client={queryClient}>
		<GoogleOAuthProvider clientId={`${process.env.REACT_APP_GG_CLIENT_ID}`}>
			<AuthProvider>
				<PayPalScriptProvider options={initialOptionsPaypal}>
					<SocketProvider>
						<CssBaseline />
						<DndProvider backend={HTML5Backend}>
							<App />
						</DndProvider>
					</SocketProvider>
				</PayPalScriptProvider>
			</AuthProvider>
		</GoogleOAuthProvider>
	</QueryClientProvider>,
);
