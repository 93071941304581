import React, { useCallback, useState } from "react";
import {
	Avatar,
	Box,
	Button,
	Grid,
	Menu,
	MenuItem,
	Popover,
	Typography,
} from "@mui/material";
import { KeyboardArrowDown, MenuOutlined } from "@mui/icons-material";
import { avtDf, logo } from "assets/images";
import { Text } from "components/Text";
import { useAuth } from "contexts";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation, useNavigate } from "react-router-dom";
import useUserStore from "stores/userStore";
import { fontSize, mainColor } from "theme/default";
import { HeaderProps } from "types";
import { MENUS } from "utils/data";
import useWindowSize from "hooks/useWindowSize";
import { Link } from "react-router-dom";
import { ModalProfile } from "components/ModalGlobal";

export const Header: React.FC<HeaderProps> = () => {
	const { logout } = useAuth();
	const { user } = useUserStore();
	const navigate = useNavigate();
	const location = useLocation();
	const width = useWindowSize();
	const [openModalProfile, setOpenModalProfile] = useState(false);

	const handleNavMenu = useCallback((link: string) => {
		navigate(link);
	}, []);

	const navigateProfile = useCallback(() => {
		setOpenModalProfile(true);
	}, []);

	// =========== OPEN MODAL INFO =============
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null,
	);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	// =========== OPEN MODAL MENU =============
	const [openMenu, setOpenMenu] = React.useState<null | HTMLElement>(null);
	const isOpenMenu = Boolean(openMenu);
	const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setOpenMenu(event.currentTarget);
	};

	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			borderBottom={`1px solid ${mainColor.borderColor}`}
			component={"nav"}>
			<Grid container sx={{ height: { xs: "60px", md: "88px" } }} width={"95%"}>
				<Grid
					item
					xs={6}
					md={5}
					xl={4}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}>
					{width > 600 ? (
						<Box>
							<Link to={"/outfit"}>
								<LazyLoadImage
									src={logo}
									width={"100px"}
									alt="logo"
									effect="blur"
								/>
							</Link>
						</Box>
					) : (
						<Box padding={0}>
							<Button
								sx={{ minWidth: "50px", padding: 0 }}
								id="menu-button"
								aria-controls={isOpenMenu ? "basic-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={isOpenMenu ? "true" : undefined}
								onClick={handleClickMenu}>
								<MenuOutlined />
							</Button>
							<Menu
								id="basic-menu"
								anchorEl={openMenu}
								open={isOpenMenu}
								onClose={() => setOpenMenu(null)}
								MenuListProps={{
									"aria-labelledby": "menu-button",
								}}>
								{MENUS.map((item) => {
									return (
										<MenuItem
											sx={{
												fontSize: fontSize.xs,
											}}
											disabled={
												item.title !== "Outfit" && item.title !== "Plan"
											}
											key={item?.link}
											onClick={() => {
												setOpenMenu(null);
												navigate(item?.link);
											}}>
											{item.title}
										</MenuItem>
									);
								})}
							</Menu>
						</Box>
					)}
					<Box
						position={"relative"}
						justifyContent={"space-between"}
						sx={{
							display: { xs: "none", sm: "flex" },
							width: { xs: "350px", xl: "504px" },
						}}>
						{MENUS.map((page) => {
							const isActive = location.pathname === page.link;
							return (
								<Button
									disabled={page.title !== "Outfit" && page.title !== "Plan"}
									key={page.title}
									onClick={() => handleNavMenu(page.link)}
									sx={{
										flex: 1,
										maxWidth: "160px",
										color:
											(isActive ? mainColor.textMain : mainColor.textGray) +
											"!important",
										textTransform: "capitalize",
										fontSize: {
											md: fontSize.xs,
											xl: fontSize.sm,
										},
										fontWeight: 600,
										height: "40px",
										border: isActive
											? `1px solid ${mainColor.secondary}`
											: "none",
										borderBottom: isActive
											? `3px solid ${mainColor.secondary}`
											: "none",
									}}>
									{page.title}
								</Button>
							);
						})}
					</Box>
				</Grid>
				<Grid
					item
					xs={6}
					md={7}
					xl={8}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"flex-end"}>
					<Box
						component={"button"}
						display={"flex"}
						alignItems={"center"}
						bgcolor={"transparent"}
						border={"none"}
						sx={{ cursor: "pointer", gap: { xs: "10px", md: "20px" } }}
						onClick={handleClick}
						aria-describedby={id}>
						<Avatar
							src={user?.avatar?.length ? user?.avatar : avtDf}
							sx={{ display: { xs: "none", md: "flex" } }}
						/>
						<Box display={"flex"} alignItems={"center"} gap={"5px"}>
							<Typography fontSize={fontSize.sm} color={mainColor.textMain}>
								{user?.userName ?? user?.email}
							</Typography>
							<KeyboardArrowDown style={{ color: mainColor.textMain }} />
						</Box>
					</Box>
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
						sx={{ overflow: "hidden", borderRadius: "10px" }}>
						<Box display={"flex"} overflow={"hidden"} flexDirection={"column"}>
							<Button
								onClick={navigateProfile}
								sx={{
									backgroundColor: mainColor.blackDark,
									border: `1px solid ${mainColor.borderColor}`,
									borderRadius: "2px",
									height: "50px",
								}}>
								<Text
									useI18n
									textAlign={"left"}
									width={"100%"}
									color={mainColor.textMain}
									textTransform={"capitalize"}
									fontSize={fontSize.xs}
									sx={{ p: 2 }}>
									Profile
								</Text>
							</Button>
							<Button
								onClick={() => {
									handleNavMenu("/pricing-plan");
									handleClose();
								}}
								sx={{
									backgroundColor: mainColor.blackDark,
									border: `1px solid ${mainColor.borderColor}`,
									borderRadius: "2px",
									height: "50px",
								}}>
								<Text
									useI18n
									textAlign={"left"}
									width={"100%"}
									color={mainColor.textMain}
									textTransform={"capitalize"}
									fontSize={fontSize.xs}
									sx={{ p: 2 }}>
									Upgrade Plan
								</Text>
							</Button>
							<Button
								onClick={logout}
								sx={{
									backgroundColor: mainColor.blackDark,
									border: `1px solid ${mainColor.borderColor}`,
									borderRadius: "2px",
									height: "50px",
								}}>
								<Text
									useI18n
									textAlign={"left"}
									width={"100%"}
									color={mainColor.textMain}
									textTransform={"capitalize"}
									fontSize={fontSize.xs}
									sx={{ p: 2 }}>
									Log out
								</Text>
							</Button>
						</Box>
					</Popover>
				</Grid>
			</Grid>
			{/* ============ Modal Profile ============== */}
			<ModalProfile
				open={openModalProfile}
				onClose={() => setOpenModalProfile(false)}
			/>
		</Box>
	);
};
