import { END_POINT } from "utils";
import axiosInstance from "./axios";
import { IGallery, IModelType, TParams, TResponse } from "types";

export const modelService = {
	getModelServiceList: async (
		params: TParams,
		endpoint = END_POINT.MODEL.GET_EXAMPLE,
	): Promise<TResponse<IModelType[] & IGallery[]>> => {
		const { page, limit, ...restParams } = params;
		// Construct the additional query string parameters
		const queryParams = new URLSearchParams(
			restParams as Record<string, string>,
		).toString();

		// Construct the full URL
		const url = `${endpoint}?page=${page}&limit=${limit}&${queryParams}`;

		const data = await axiosInstance.get(url);
		return data?.data
	},
};
