import {
	Box,
	FormControl,
	MenuItem,
	OutlinedInput,
	Select,
} from "@mui/material";
import { ImageUpload, PaginationCommon, Text } from "components";
import React, { useState } from "react";
import useOutfitStore from "stores/outfitStore";
import { EDropType, IClothesProps, TParams } from "types";
import ListImage from "./ImageList";
import { useClothes } from "hooks";
import Heading from "components/Heading/Heading";
import { fontSize, mainColor } from "theme/default";
import { CATEGORY, SIZE_BODY } from "utils/data";

export const Clothes: React.FC<IClothesProps> = ({
	valueCategory,
	setValueCategory,
	valueSize,
	setValueSize,
}) => {
	// ======================== HOOKS ========================
	const {
		setSelectedFileClothes,
		setImageClothes,
		imageClothes,
		selectedFileClothes,
	} = useOutfitStore();
	const { useGetClothes } = useClothes();

	// ======================== GET DATA WITH REACT_QUERY ========================

	const [paramsOutfitDresses, setParamsOutfitDresses] = useState<TParams>({
		page: 1,
		limit: 10,
		typeOutfit: "DRESSES",
	});
	const { data: dataOutfitDresses, isLoading: isLoadingDresses } =
		useGetClothes(paramsOutfitDresses);

	const [paramsOutfitLower, setParamsOutfitLower] = useState<TParams>({
		page: 1,
		limit: 10,
		typeOutfit: "LOWER",
	});
	const { data: dataOutfitLower, isLoading: isLoadingLower } =
		useGetClothes(paramsOutfitLower);

	const [paramsOutfitUpper, setParamsOutfitUpper] = useState<TParams>({
		page: 1,
		limit: 10,
		typeOutfit: "UPPER",
	});
	const { data: dataOutfitUpper, isLoading: isLoadingUpper } =
		useGetClothes(paramsOutfitUpper);

	const isShowUpperBody = valueCategory === "upper_body";
	const isShowLowerBody = valueCategory === "lower_body";
	const isShowDressBody = valueCategory === "dresses";
	return (
		<Box>
			<Box>
				<ImageUpload
					onFileSelect={(file) => setSelectedFileClothes(file)}
					onClear={() => setImageClothes("")}
					initialImage={imageClothes as string}
					description="Drop or upload your outfit"
					title="Your Item"
					dropType={EDropType.OUTFIT}
					valueCategory={valueCategory}
				/>
			</Box>

			{/* ====================== SELECT OPTION ====================*/}
			<Box
				borderRadius={"8px"}
				mt={"45px"}
				bgcolor={mainColor.mainBlack}
				padding={"10px 20px"}>
				<Text
					useI18n
					color={mainColor.textMain}
					fontWeight={"500"}
					fontSize={fontSize.xs}>
					Select Category
				</Text>
				<FormControl
					sx={{
						width: "100%",
						marginTop: "5px",
						border: `1px solid ${mainColor.borderColor}`,
						borderRadius: "5px",
						color: "white",
						outline: "none",
					}}>
					<Select
						sx={{ outline: "none" }}
						displayEmpty
						value={valueCategory}
						onChange={(e) => setValueCategory?.(e.target?.value)}
						input={<OutlinedInput />}
						inputProps={{ "aria-label": "Without label" }}>
						{CATEGORY.map((item) => (
							<MenuItem
								sx={{ fontSize: fontSize.xs }}
								key={item.value}
								value={item.value}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>

			<Box
				borderRadius={"8px"}
				mt={"22px"}
				bgcolor={mainColor.mainBlack}
				padding={"10px 20px"}>
				<Text
					useI18n
					color={mainColor.textMain}
					fontWeight={"500"}
					fontSize={fontSize.xs}>
					Length
				</Text>
				<FormControl
					sx={{
						width: "100%",
						marginTop: "5px",
						border: `1px solid ${mainColor.borderColor}`,
						borderRadius: "5px",
						color: "white",
						outline: "none",
					}}>
					<Select
						sx={{ outline: "none" }}
						displayEmpty
						value={valueSize}
						onChange={(e) => setValueSize?.(e.target?.value)}
						input={<OutlinedInput />}
						inputProps={{ "aria-label": "Without label" }}>
						{SIZE_BODY.map((item) => (
							<MenuItem
								sx={{ fontSize: fontSize.xs }}
								key={item.value}
								value={item.value}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>

			{/* ===================== UPPER BODY ===================== */}
			<Box
				sx={{
					mt: isShowUpperBody ? 4 : 0,
					height: isShowUpperBody
						? {
								xs: "auto",
							}
						: 0,
					overflow: isShowUpperBody ? "unset" : "hidden",
					transition: "all 0.25s ease-in-out",
				}}>
				<Heading title="Upper-body" />
				<ListImage
					isLoading={isLoadingUpper}
					images={dataOutfitUpper?.data}
					selectedImage={selectedFileClothes as string}
					setSelectedImage={(it) => {
						setSelectedFileClothes(it.id as string);
						setImageClothes(it.image as string);
					}}
					dropType={EDropType.OUTFIT}
				/>
				<Box display={"flex"} alignItems={"center"} justifyContent={"end"}>
					<PaginationCommon
						total={dataOutfitUpper?.pagination?.totalPage as number}
						onChange={(p: number) => {
							setParamsOutfitUpper({ ...paramsOutfitUpper, page: p });
						}}
					/>
				</Box>
			</Box>

			{/* =================== LOWER BODY ===================== */}
			<Box
				sx={{
					mt: isShowLowerBody ? 4 : 0,
					height: isShowLowerBody
						? {
								xs: "auto",
							}
						: 0,
					overflow: isShowLowerBody ? "unset" : "hidden",
					transition: "all 0.25s ease-in-out",
				}}>
				<Heading title="Lower-body" />
				<ListImage
					isLoading={isLoadingLower}
					images={dataOutfitLower?.data}
					selectedImage={selectedFileClothes as string}
					setSelectedImage={(it) => {
						setSelectedFileClothes(it.id as string);
						setImageClothes(it.image as string);
					}}
					dropType={EDropType.OUTFIT}
				/>
				<Box display={"flex"} alignItems={"center"} justifyContent={"end"}>
					<PaginationCommon
						total={dataOutfitLower?.pagination?.totalPage as number}
						onChange={(p: number) => {
							setParamsOutfitLower({ ...paramsOutfitLower, page: p });
						}}
					/>
				</Box>
			</Box>

			{/* =================== DRESS BODY ===================== */}
			<Box
				sx={{
					mt: isShowDressBody ? 4 : 0,
					height: isShowDressBody
						? {
								xs: "auto",
							}
						: 0,
					overflow: isShowDressBody ? "unset" : "hidden",
					transition: "all 0.25s ease-in-out",
				}}>
				<Heading title="Dress-body" />
				<ListImage
					isLoading={isLoadingDresses}
					images={dataOutfitDresses?.data}
					selectedImage={selectedFileClothes as string}
					setSelectedImage={(it) => {
						setSelectedFileClothes(it.id as string);
						setImageClothes(it.image as string);
					}}
					dropType={EDropType.OUTFIT}
				/>
				<Box display={"flex"} alignItems={"center"} justifyContent={"end"}>
					<PaginationCommon
						total={dataOutfitDresses?.pagination?.totalPage as number}
						onChange={(p: number) => {
							setParamsOutfitDresses({ ...paramsOutfitDresses, page: p });
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};
