import React from "react";
import { Box, ImageListItem } from "@mui/material";
import { useDrag } from "react-dnd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { mainColor } from "theme/default";
import { IModelType } from "types";

interface IImageDrag {
	handleClick: (item: IModelType) => void;
	item: IModelType;
	selectedImage: string | undefined;
	dropType: string;
}

const ImageDrag = ({
	handleClick,
	item,
	selectedImage,
	dropType,
}: IImageDrag) => {
	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: dropType,
			item: item,
			end: (item, monitor) => {
				const dropResult = monitor.getDropResult<IImageDrag>();
				if (item && dropResult) {
					handleClick(item);
				}
			},
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
		}),
		[],
	);

	return (
		<>
			<ImageListItem
				sx={{
					position: "relative",
					cursor: "pointer",
					transition: "all 0.25s ease-in-out",
					overflow: "hidden",
					height: "auto",
				}}>
				<Box
					sx={{ opacity: isDragging ? 0.4 : 1 }}
					ref={drag}
					borderRadius={"5px"}
					display={"flex"}
					border={`${isDragging ? "1px" : "0px"} dashed #ffffff`}
					justifyContent={"center"}>
					<LazyLoadImage
						style={{
							borderRadius: "5px",
							width: "100%",
							height: "100%",
							transition: "all 0.25s ease-in-out",
							display: "block",
						}}
						onClick={() => handleClick(item)}
						src={`${item?.image}?w=164&h=164&fit=crop&auto=format`}
						srcSet={`${item?.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
						alt={item?.name}
						loading="lazy"
						effect="blur"
					/>
				</Box>

				<Box
					position={"absolute"}
					bgcolor={"#5adbfc59"}
					top={0}
					width={"100%"}
					height={"100%"}
					sx={{
						left:
							selectedImage === item?.id ? 0 : { xs: "-90px", md: "-195px" },
						transition: "all 0.25s ease-in-out",
						borderRadius: "5px",
						border:
							selectedImage === item?.id
								? `3px solid ${mainColor.secondary}`
								: `0px solid ${mainColor.secondary}`,
					}}></Box>
			</ImageListItem>
		</>
	);
};

export default ImageDrag;
