import { END_POINT } from "utils";
import axiosInstance from "./axios";
import { ResponsePlan, TPayloadApprove, TPayloadOrder, TPayloadPayment } from "types/payment";
import { TResponse } from "types";

export const paymentService = {

	listPlan: async (quantity: number) => {
		const data = await axiosInstance.get(END_POINT.PAYMENT.PLAN + `?quantity=${quantity}`)
		return data?.data
	},
	
	detailPlan: async (idPlan: string): Promise<TResponse<ResponsePlan>> => {
		const data = await axiosInstance.get(END_POINT.PAYMENT.DETAIL_PLAN + idPlan)
		return data?.data
	},

	paymentPaypal: async (payload:TPayloadPayment) => {
		return await axiosInstance.post(END_POINT.PAYMENT.PAYMENT_PAYPAL, {
			...payload
		});
	},

	orderPaypal: async (payload:TPayloadOrder) => {
		const data =  await axiosInstance.post(END_POINT.PAYMENT.ORDER,{
            ...payload
        });
		return data?.data
	},
    
    approvePaypal: async (payload:TPayloadApprove) => {
		const data = await axiosInstance.post(END_POINT.PAYMENT.APPROVE,{
            ...payload
        });
		return data?.data
	},
};
