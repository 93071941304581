import { END_POINT } from "utils";
import axiosInstance from "./axios";
import { IFaceSwapParams, IOutfitParams } from "types";

export const aiService = {
	outfitTools: async (params: IOutfitParams) => {
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};

		return await axiosInstance.post(
			END_POINT.AI_TOOLS.OUTFIT_TOOLS,
			params,
			config,
		);
	},
	faceSwapTools: async (params: IFaceSwapParams) => {
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};

		return await axiosInstance.post(
			END_POINT.AI_TOOLS.FACE_SWAP,
			params,
			config,
		);
	},
};
