/** @format */

import { SvgIconCus } from "components/SvgIcon";
import React from "react";
import { ISVGIcon } from "types";

const Icons = {
	Close: () => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect width="24" height="24" rx="12" fill="#EBE7FF" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.46967 7.46967C7.76256 7.17678 8.23744 7.17678 8.53033 7.46967L12 10.9393L15.4697 7.46967C15.7626 7.17678 16.2374 7.17678 16.5303 7.46967C16.8232 7.76256 16.8232 8.23744 16.5303 8.53033L13.0607 12L16.5303 15.4697C16.8232 15.7626 16.8232 16.2374 16.5303 16.5303C16.2374 16.8232 15.7626 16.8232 15.4697 16.5303L12 13.0607L8.53033 16.5303C8.23744 16.8232 7.76256 16.8232 7.46967 16.5303C7.17678 16.2374 7.17678 15.7626 7.46967 15.4697L10.9393 12L7.46967 8.53033C7.17678 8.23744 7.17678 7.76256 7.46967 7.46967Z"
				fill="#21065D"
			/>
		</svg>
	),
	ArrowRight: ({
		width,
		height,
		size = 24,
		wrapColor,
		color = "#9A9A9E",
		viewBox = "0 0 24 24",
		...props
	}: ISVGIcon) => (
		<SvgIconCus
			width={width}
			size={size}
			height={height}
			wrapColor={wrapColor}
			viewBox={viewBox}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L16.5303 11.4697C16.8232 11.7626 16.8232 12.2374 16.5303 12.5303L10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303C9.17678 18.2374 9.17678 17.7626 9.46967 17.4697L14.9393 12L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967Z"
				fill={color}
				{...props}
			/>
		</SvgIconCus>
	),
	ArrowDown: () => (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_4401_21665)">
				<path
					d="M10.5867 6.19305L7.99999 8.77971L5.41332 6.19305C5.15332 5.93305 4.73332 5.93305 4.47332 6.19305C4.21332 6.45305 4.21332 6.87305 4.47332 7.13305L7.53332 10.193C7.79332 10.453 8.21332 10.453 8.47332 10.193L11.5333 7.13305C11.7933 6.87305 11.7933 6.45305 11.5333 6.19305C11.2733 5.93971 10.8467 5.93305 10.5867 6.19305Z"
					fill="black"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4401_21665">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	EyeHide: () => (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.53009 2.46967C3.23719 2.17678 2.76232 2.17678 2.46943 2.46967C2.17653 2.76256 2.17653 3.23744 2.46943 3.53033L4.58578 5.64668C3.61207 6.32254 2.87547 7.12109 2.35985 7.90076C1.75793 8.81095 1.4165 9.75949 1.4165 10.5C1.4165 11.0782 1.62448 11.7725 1.98309 12.461C2.34869 13.163 2.8965 13.9092 3.63696 14.5927C5.12687 15.968 7.39085 17.0833 10.4998 17.0833C12.2959 17.0833 13.814 16.711 15.0659 16.1268L17.4694 18.5303C17.7623 18.8232 18.2372 18.8232 18.5301 18.5303C18.823 18.2374 18.823 17.7626 18.5301 17.4697L3.53009 2.46967ZM13.9229 14.9838L12.2057 13.2666C11.7098 13.5729 11.125 13.75 10.4998 13.75C8.70483 13.75 7.24976 12.2949 7.24976 10.5C7.24976 9.87478 7.42685 9.28992 7.73312 8.79403L5.66715 6.72806C4.74179 7.3217 4.06395 8.04327 3.61101 8.72817C3.10202 9.49782 2.9165 10.1583 2.9165 10.5C2.9165 10.7551 3.02103 11.2066 3.31346 11.7681C3.5989 12.3161 4.04068 12.924 4.65438 13.4905C5.8728 14.6152 7.77549 15.5833 10.4998 15.5833C11.8353 15.5833 12.9706 15.3507 13.9229 14.9838ZM8.85086 9.91177C8.78535 10.0955 8.74976 10.2934 8.74976 10.5C8.74976 11.4665 9.53326 12.25 10.4998 12.25C10.7064 12.25 10.9043 12.2144 11.088 12.1489L8.85086 9.91177ZM10.4998 5.41665C9.64208 5.41665 8.86806 5.51261 8.17314 5.67765C7.77014 5.77337 7.36585 5.52426 7.27014 5.12126C7.17442 4.71826 7.42353 4.31397 7.82653 4.21825C8.63884 4.02532 9.52936 3.91665 10.4998 3.91665C13.6088 3.91665 15.8728 5.03191 17.3627 6.40721C18.1032 7.09072 18.651 7.83698 19.0166 8.53895C19.3752 9.22747 19.5832 9.92172 19.5832 10.5C19.5832 11.5532 18.8904 13.0785 17.6007 14.3644C17.3074 14.6569 16.8325 14.6562 16.5401 14.3629C16.2476 14.0695 16.2483 13.5947 16.5416 13.3022C17.6598 12.1873 18.0832 11.0216 18.0832 10.5C18.0832 10.2449 17.9786 9.79332 17.6862 9.23185C17.4008 8.68381 16.959 8.07591 16.3453 7.50942C15.1269 6.38472 13.2242 5.41665 10.4998 5.41665Z"
				fill="#ccc"
			/>
		</svg>
	),
	Eye: () => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.75 12C2.75 11.6616 2.88577 11.0954 3.24331 10.409C3.59385 9.73591 4.13459 8.9926 4.88371 8.3011C6.37297 6.9264 8.69232 5.75 12 5.75C15.3077 5.75 17.627 6.9264 19.1163 8.3011C19.8654 8.9926 20.4061 9.73591 20.7567 10.409C21.1142 11.0954 21.25 11.6616 21.25 12C21.25 12.3384 21.1142 12.9046 20.7567 13.591C20.4061 14.2641 19.8654 15.0074 19.1163 15.6989C17.627 17.0736 15.3077 18.25 12 18.25C8.69232 18.25 6.37297 17.0736 4.88371 15.6989C4.13459 15.0074 3.59385 14.2641 3.24331 13.591C2.88577 12.9046 2.75 12.3384 2.75 12ZM12 4.25C8.30768 4.25 5.62703 5.5736 3.86629 7.1989C2.99041 8.0074 2.34365 8.88909 1.91294 9.71605C1.48923 10.5296 1.25 11.3384 1.25 12C1.25 12.6616 1.48923 13.4704 1.91294 14.284C2.34365 15.1109 2.99041 15.9926 3.86629 16.8011C5.62703 18.4264 8.30768 19.75 12 19.75C15.6923 19.75 18.373 18.4264 20.1337 16.8011C21.0096 15.9926 21.6564 15.1109 22.0871 14.284C22.5108 13.4704 22.75 12.6616 22.75 12C22.75 11.3384 22.5108 10.5296 22.0871 9.71605C21.6564 8.88909 21.0096 8.0074 20.1337 7.1989C18.373 5.5736 15.6923 4.25 12 4.25ZM9.7499 12C9.7499 10.7574 10.7573 9.75002 11.9999 9.75002C13.2425 9.75002 14.2499 10.7574 14.2499 12C14.2499 13.2427 13.2425 14.25 11.9999 14.25C10.7573 14.25 9.7499 13.2427 9.7499 12ZM11.9999 8.25002C9.92883 8.25002 8.2499 9.92896 8.2499 12C8.2499 14.0711 9.92883 15.75 11.9999 15.75C14.071 15.75 15.7499 14.0711 15.7499 12C15.7499 9.92896 14.071 8.25002 11.9999 8.25002Z"
				fill="#ccc"
			/>
		</svg>
	),
	GoogleIcon: () => (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.26 12.273C24.26 11.4221 24.1837 10.6039 24.0418 9.81848H12.74V14.4603H19.1982C18.92 15.9603 18.0745 17.2312 16.8036 18.0821V21.0931H20.6818C22.9509 19.004 24.26 15.9276 24.26 12.273Z"
				fill="#4285F4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.74 23.9999C15.98 23.9999 18.6964 22.9254 20.6819 21.0926L16.8037 18.0817C15.7291 18.8017 14.3546 19.2272 12.74 19.2272C9.61456 19.2272 6.96909 17.1163 6.02546 14.2799H2.01636V17.389C3.99091 21.3108 8.0491 23.9999 12.74 23.9999Z"
				fill="#34A853"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.02546 14.2801C5.78546 13.5601 5.64909 12.791 5.64909 12.0001C5.64909 11.2092 5.78546 10.4401 6.02546 9.72006V6.61096H2.01636C1.20363 8.23097 0.73999 10.0637 0.73999 12.0001C0.73999 13.9364 1.20363 15.7692 2.01636 17.3892L6.02546 14.2801Z"
				fill="#FBBC05"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.74 4.77286C14.5018 4.77286 16.0837 5.37832 17.3273 6.56741L20.7691 3.12558C18.6909 1.18922 15.9746 0.00012207 12.74 0.00012207C8.0491 0.00012207 3.99091 2.68922 2.01636 6.61104L6.02546 9.72014C6.96909 6.88377 9.61455 4.77286 12.74 4.77286Z"
				fill="#EA4335"
			/>
		</svg>
	),
	UnCheckBox: () => (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect x="1" y="1" width="17" height="17" rx="5.5" fill="white" />
			<rect x="1" y="1" width="17" height="17" rx="5.5" stroke="black" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.6738 5.44714C14.9791 5.72703 14.9998 6.20146 14.7199 6.5068L8.3032 13.5068C8.16497 13.6576 7.97114 13.7454 7.76662 13.7498C7.56211 13.7543 7.36465 13.675 7.22 13.5303L4.30333 10.6137C4.01044 10.3208 4.01044 9.8459 4.30333 9.55301C4.59623 9.26011 5.0711 9.26011 5.36399 9.55301L7.72677 11.9158L13.6141 5.49321C13.894 5.18787 14.3685 5.16724 14.6738 5.44714Z"
				fill="white"
			/>
		</svg>
	),
	Checked: () => (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect x="0.5" y="0.5" width="18" height="18" rx="6" fill="#21065D" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.6737 5.44714C14.9791 5.72703 14.9997 6.20146 14.7198 6.5068L8.30315 13.5068C8.16492 13.6576 7.97109 13.7454 7.76658 13.7498C7.56206 13.7543 7.3646 13.675 7.21995 13.5303L4.30329 10.6137C4.01039 10.3208 4.01039 9.8459 4.30329 9.55301C4.59618 9.26011 5.07105 9.26011 5.36395 9.55301L7.72673 11.9158L13.6141 5.49321C13.894 5.18787 14.3684 5.16724 14.6737 5.44714Z"
				fill="white"
			/>
		</svg>
	),
	Upload: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none">
			<rect x="0.166016" width="24" height="24" rx="12" fill="#5ADBFC" />
			<path
				d="M8.41601 17C8.07226 17 7.7781 16.8744 7.53352 16.6231C7.28893 16.3719 7.16643 16.0694 7.16602 15.7159V14.4318C7.16602 14.2499 7.22602 14.0975 7.34602 13.9746C7.46602 13.8518 7.61435 13.7902 7.79102 13.7897C7.96768 13.7893 8.11622 13.8509 8.23664 13.9746C8.35706 14.0983 8.41685 14.2507 8.41601 14.4318V15.7159H15.916V14.4318C15.916 14.2499 15.976 14.0975 16.096 13.9746C16.216 13.8518 16.3643 13.7902 16.541 13.7897C16.7177 13.7893 16.8662 13.8509 16.9866 13.9746C17.107 14.0983 17.1668 14.2507 17.166 14.4318V15.7159C17.166 16.069 17.0437 16.3714 16.7991 16.6231C16.5545 16.8748 16.2602 17.0004 15.916 17H8.41601ZM11.541 9.19904L10.3691 10.4029C10.2441 10.5313 10.0958 10.5929 9.92414 10.5878C9.75247 10.5827 9.60393 10.5157 9.47851 10.3868C9.36393 10.2584 9.30393 10.1086 9.29851 9.93741C9.2931 9.76619 9.3531 9.61638 9.47851 9.48797L11.7285 7.17657C11.791 7.11237 11.8587 7.067 11.9316 7.04046C12.0046 7.01392 12.0827 7.00044 12.166 7.00001C12.2493 6.99958 12.3275 7.01306 12.4004 7.04046C12.4733 7.06785 12.541 7.11323 12.6035 7.17657L14.8535 9.48797C14.9785 9.61638 15.0385 9.76619 15.0335 9.93741C15.0285 10.1086 14.9685 10.2584 14.8535 10.3868C14.7285 10.5153 14.5802 10.5822 14.4085 10.5878C14.2368 10.5934 14.0883 10.5317 13.9629 10.4029L12.791 9.19904V13.7897C12.791 13.9716 12.731 14.1242 12.611 14.2475C12.491 14.3708 12.3427 14.4322 12.166 14.4318C11.9893 14.4314 11.841 14.3697 11.721 14.2469C11.601 14.124 11.541 13.9716 11.541 13.7897V9.19904Z"
				fill="white"
			/>
		</svg>
	),
	List: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="19"
			height="16"
			viewBox="0 0 19 16"
			fill="none">
			<path
				d="M2 6.5C1.17 6.5 0.5 7.17 0.5 8C0.5 8.83 1.17 9.5 2 9.5C2.83 9.5 3.5 8.83 3.5 8C3.5 7.17 2.83 6.5 2 6.5ZM2 0.5C1.17 0.5 0.5 1.17 0.5 2C0.5 2.83 1.17 3.5 2 3.5C2.83 3.5 3.5 2.83 3.5 2C3.5 1.17 2.83 0.5 2 0.5ZM2 12.5C1.17 12.5 0.5 13.18 0.5 14C0.5 14.82 1.18 15.5 2 15.5C2.82 15.5 3.5 14.82 3.5 14C3.5 13.18 2.83 12.5 2 12.5ZM6 15H18C18.55 15 19 14.55 19 14C19 13.45 18.55 13 18 13H6C5.45 13 5 13.45 5 14C5 14.55 5.45 15 6 15ZM6 9H18C18.55 9 19 8.55 19 8C19 7.45 18.55 7 18 7H6C5.45 7 5 7.45 5 8C5 8.55 5.45 9 6 9ZM5 2C5 2.55 5.45 3 6 3H18C18.55 3 19 2.55 19 2C19 1.45 18.55 1 18 1H6C5.45 1 5 1.45 5 2Z"
				fill="white"
			/>
		</svg>
	),
};

export default Icons;
