import { useQuery } from "@tanstack/react-query";
import { clothesService } from "services/clothesService";
import {  TParams } from "types";
import { QueryKeys } from "utils";

export const useClothes = () => {



	const useGetClothes = (params: TParams) => {
		const { data, refetch, isLoading, isError } = useQuery({
			queryKey: [QueryKeys.GET_CLOTHES, params.page, params.typeOutfit],
			queryFn: () => clothesService.getClothesExample({ ...params }),
		});
		return {data, refetch, isLoading, isError}
	}

	

	return {
		useGetClothes
	};
};
