import React, { useRef } from "react";
import { Box } from "@mui/material";
import { useState } from "react";
import { ButtonCus, Text } from "components";
import { fontSize, mainColor } from "theme/default";
import { logoAuth } from "assets/images";
import CustomFormControlInput from "components/Inputs/InputOutLine";
import { Controller, Resolver, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dynamicSchema } from "utils";
import {
	ErrorOtpForm,
	ErrorOtpMessage,
	ForgotForm,
	OTPForm,
	TypeSendMailOtp,
} from "types";
import useUserStore from "stores/userStore";
import { useMutation } from "@tanstack/react-query";
import { authService } from "services/authService";
import { useAlert, useTimer } from "hooks";
import { userService } from "services/userService";
import { useNavigate } from "react-router-dom";

const ForgotPage = () => {
	const [isOtpStep, setIsOtpStep] = useState(false);
	const refEmail = useRef({ email: "" }).current;
	const { success, warn } = useAlert();
	const { setNewPassViaUid } = useUserStore();
	const { seconds, isActive, startTimer } = useTimer(30);

	const navigate = useNavigate();
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid, isSubmitting },
	} = useForm<ForgotForm | OTPForm>({
		resolver: yupResolver(dynamicSchema(isOtpStep)) as unknown as Resolver<
			ForgotForm | OTPForm
		>,
		defaultValues: {
			email: "",
			otp: "",
		},
	});

	const sendOtpMutation = useMutation({
		mutationFn: (formData: ForgotForm) =>
			userService.getOtpViaMail(
				formData.email,
				TypeSendMailOtp.FOR_GOT_PASS_WORD,
			),
		onSuccess: ({ data }) => {
			if (data?.statusCode === 200) {
				success(`Otp has been sent to email successfully`);
				setIsOtpStep(true);
				startTimer();
				return;
			}
			warn(data?.message || "Failed to send otp");
		},
		onError: () => {
			warn("Change failed!");
		},
	});

	const verifyOtpMutation = useMutation({
		mutationFn: (formData: OTPForm) => authService.verifyOtp(formData.otp),
		onSuccess: ({ data }) => {
			if (data?.statusCode === 200) {
				setNewPassViaUid(data?.data?.uid);

				navigate("/reset-password");
				setIsOtpStep(false);

				return;
			}
			warn(data?.message || "Failed to send otp");
		},
		onError: () => {
			warn("Change failed!");
		},
	});

	const onSubmit: SubmitHandler<ForgotForm | OTPForm> = (data) => {
		if (!isOtpStep) {
			refEmail.email = data.email; // keep email to resend otp
			sendOtpMutation.mutate(data);
			reset();
		} else {
			verifyOtpMutation.mutate(data as OTPForm);
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: mainColor.primary,
			}}>
			<Box
				bgcolor={mainColor.mainBlack}
				width={"559px"}
				height={"860px"}
				borderRadius={"32px"}
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					padding: 3,
				}}>
				<Box mb={5}>
					<img src={logoAuth} alt="logo" />
				</Box>
				<Text
					variant="h2"
					gutterBottom
					useI18n
					sx={{ fontWeight: 500 }}
					color={mainColor.textMain}>
					Forgot password
				</Text>
				<Text
					variant="h6"
					gutterBottom
					useI18n
					sx={{ fontWeight: 400 }}
					color={mainColor.textMain}>
					Enter the email for the forgotten account.
				</Text>
				<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
					{!isOtpStep ? (
						<Controller
							name="email"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<CustomFormControlInput
									label="Email"
									value={field.value}
									setValue={field.onChange}
									error={!!errors.email}
									errorMessage={errors?.email?.message}
									fullWidth
									sx={{
										mt: 5,
										mb: 2.5,
									}}
								/>
							)}
						/>
					) : (
						<Controller
							name="otp"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<CustomFormControlInput
									label="OTP"
									value={`${field.value}`}
									setValue={field.onChange}
									error={!!(errors as ErrorOtpForm)?.otp}
									errorMessage={(errors as ErrorOtpMessage)?.otp?.message}
									fullWidth
									sx={{
										mt: 5,
										mb: 2.5,
									}}
								/>
							)}
						/>
					)}
					<ButtonCus
						sx={{
							mt: 2,
							backgroundColor: isValid
								? mainColor.secondary
								: mainColor.secondary,
						}}
						fullWidth
						type="submit"
						disabled={!isValid || isSubmitting}>
						<Text
							useI18n
							color={mainColor.textMain}
							fontSize={fontSize.sm}
							fontWeight={"600"}>
							Reset Password
						</Text>
					</ButtonCus>
				</form>
				{isOtpStep && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
						}}>
						<Text useI18n color={mainColor.textMain}>
							Didn’t get any email?
						</Text>
						<ButtonCus
							sx={{
								width: "auto",
								p: 0,
								minWidth: "auto",
								ml: 0.5,
							}}
							variant="text"
							fullWidth
							onClick={() => {
								if (!isActive) {
									sendOtpMutation.mutate({ email: refEmail.email });
									startTimer();
								}
							}}
							disabled={isActive}>
							<Text useI18n variant="body1" color={mainColor.secondary}>
								Resend
							</Text>
							{isActive ? (
								<Text useI18n variant="body1" color={mainColor.textMain}>
									{` (${seconds}s)`}
								</Text>
							) : (
								""
							)}
						</ButtonCus>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default ForgotPage;
